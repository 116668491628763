import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';



@Injectable({
  providedIn: 'root'
})
export class FoliosService {

  constructor(
    private _http: HttpClient,
    // private _ss: StorageService,
    // private _router: Router
  ) {

  }

  async getFolios(filter) {

    return this._http.get(`${URL}/usuario-folio?${filter}`)
      .toPromise()
  }

  async postFolios(post) {

    return this._http.post(`${URL}/usuario-folio`, post)
      .toPromise()
  }

  async putFolios(put, id) {

    return this._http.put(`${URL}/usuario-folio/${id}`, put)
      .toPromise()
  }


}
