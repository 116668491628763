import { NgModule } from "@angular/core";
import { MatButtonModule } from '@angular/material/button'
import { MatToolbarModule } from '@angular/material/toolbar'
import { MatIconModule } from '@angular/material/icon'
import { MatSidenavModule } from '@angular/material/sidenav'
import { MatListModule } from '@angular/material/list'
import { MatInputModule } from '@angular/material/input'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatDialogModule } from '@angular/material/dialog'
import { MatDividerModule } from '@angular/material/divider'
import { MatMenuModule } from '@angular/material/menu'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatCardModule } from '@angular/material/card'
import { MatBadgeModule } from '@angular/material/badge'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatTableModule } from '@angular/material/table'
import { MatTooltipModule } from '@angular/material/tooltip'
import { MatSortModule } from '@angular/material/sort'
import { MatStepperModule } from '@angular/material/stepper'
import { MatRadioModule } from '@angular/material/radio'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatDatepickerModule } from '@angular/material/datepicker'
// import {MatNativeDateModule} from '@angular/material/'
import { MatSelectModule } from '@angular/material/select'
// import {MatRippleModule} from '@angular/material'
import { MatTabsModule } from '@angular/material/tabs'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { FlexLayoutModule } from "@angular/flex-layout";
import { MatNativeDateModule } from '@angular/material/core';
import { MatChipsModule } from '@angular/material/chips';
// import { AppDateAdapter, APP_DATE_FORMATS } from '../helpers/format-datepicker';
import { MtxPopoverModule } from '@ng-matero/extensions/popover';


// import {
//   NgxMatDateAdapter,
//   NgxMatDateFormats,
//   NgxMatDatetimePickerModule,
//   NgxMatNativeDateModule,
//   NgxMatTimepickerModule,
//   NGX_MAT_DATE_FORMATS
// } from '@angular-material-components/datetime-picker';


// const INTL_DATE_INPUT_FORMAT = {
//   year: 'numeric',
//   month: 'numeric',
//   day: 'numeric',
//   hourCycle: 'h23',
//   hour: '2-digit',
//   minute: '2-digit',
// };
// const MAT_DATE_FORMATS: NgxMatDateFormats = {
//   parse: {
//     dateInput: INTL_DATE_INPUT_FORMAT,
//   },
//   display: {
//     dateInput: INTL_DATE_INPUT_FORMAT, //{ year: 'numeric', month: 'long', day: 'numeric' }, //INTL_DATE_INPUT_FORMAT,
//     //dateInput: 'DD MM, YYYYY HH:mm',
//     monthYearLabel: { year: 'numeric', month: 'short' },
//     dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
//     monthYearA11yLabel: { year: 'numeric', month: 'long' },
//   },
// };

@NgModule({
  imports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatStepperModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTabsModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatChipsModule,

    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,

    MtxPopoverModule,
  ],
  exports: [
    MatButtonModule,
    MatToolbarModule,
    MatIconModule,
    FlexLayoutModule,
    MatSidenavModule,
    MatListModule,
    MatInputModule,
    MatFormFieldModule,
    MatDialogModule,
    MatDividerModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatCardModule,
    MatBadgeModule,
    MatProgressBarModule,
    MatTableModule,
    MatTooltipModule,
    MatSortModule,
    MatStepperModule,
    MatRadioModule,
    MatSnackBarModule,
    MatCheckboxModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatSelectModule,
    MatTabsModule,
    MatPaginatorModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatNativeDateModule,
    MatChipsModule,

    // NgxMatDatetimePickerModule,
    // NgxMatTimepickerModule,
    // NgxMatNativeDateModule,

    MtxPopoverModule,
  ],
  providers: [
    //{ provide: NGX_MAT_DATE_FORMATS, useValue: MAT_DATE_FORMATS }
    // { provide: NgxMatDateAdapter, useClass: AppDateAdapter },
    // { provide: NGX_MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ],
})
export class AngularMaterialModule { }
