import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';
import { CatalogosService } from '../../../../../services/catalogos/catalogos.service';
import { CancelarServicioComponent } from '../../../cancelar-servicio/cancelar-servicio.component';
import { DocumentoComponent } from '../documento/documento.component';
import { RequisitoComponent } from '../requisito/requisito.component';


@Component({
  selector: 'app-producto-verificacion',
  templateUrl: './producto-verificacion.component.html',
  styleUrls: ['./producto-verificacion.component.sass']
})
export class ProductoVerificacionComponent implements OnInit {
  // regulaciones = <any>CatalogosService.
  listo = [{ valor: 1, texto: "SI" }, { valor: 0, texto: "NO" }]
  verificaciones = [{ valor: 1, texto: "Constatacion Ocular" },
  { valor: 2, texto: "Diagnostico Fitosanitario" },
  { valor: 3, texto: "Muestreo en sitio" },
  { valor: 0, texto: "Otro" }]
  regulaciones = []
  @Input() productoServicio: any;
  idServicio;

  servicioProductoForm = new FormGroup({
    // idServicioProducto: new FormControl(null, Validators.required),
    // idProducto: new FormControl(null, Validators.required),
    idRegulacion: new FormControl(null, Validators.required),
    listoAVerificar: new FormControl(null, Validators.required),
    tipoVerificacion: new FormControl(null, Validators.required),
    otroTipoVerificacion: new FormControl(null),
  })
  requisitos = []
  documentos = []
  constructor(private _cs: CatalogosService,
    private _dialog: MatDialog,
    private _utilitiesService: UtilitiesService,
    private _servicioService: ServicioService,
    private _activatedRoute: ActivatedRoute,
    private _confirmDialog: MatDialog,
    private _snakBar: MatSnackBar) {
    this._activatedRoute.params.subscribe(params => {
      this.idServicio = params['idServicio'];
      console.log('Service #: ', this.idServicio);
    });
  }

  async ngOnInit() {
    this.regulaciones = <any>await this._cs.getRegulaciones()
    console.log(this.productoServicio)
    let forma = this._utilitiesService.castObjetoAsProperties(Object.keys(this.servicioProductoForm.controls), this.productoServicio)
    this.servicioProductoForm.setValue(forma)
    this.requisitos = this.productoServicio.ServicioProductoRequisitos as Array<any>
    this.documentos = this.productoServicio.ServicioProductoDocumentos as Array<any>
  }

  addDocumento(ProdDoc = null, index = -1) {
    const dialogRef = this._dialog.open(DocumentoComponent, {
      width: '1100px',
      data: { idServicio: this.idServicio, idServicioProducto: this.productoServicio.idServicioProducto, servicioProductoDocumento: ProdDoc }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log("index", index, result);
      if (result) {
        if (index >= 0) {
          this.documentos[index] = result
        }
        else {
          this.documentos.push(result)
        }
      }
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  async delDocumento(ProdDoc, index) {
    console.log(ProdDoc);
    const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title: "Eliminar", message: "Desea eliminar este documento", cancelButton: "Cancelar", okButton: "Aceptar"
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        if (result) {
          this._servicioService.deleteServicioProductoDoc(this.idServicio, this.productoServicio.idServicioProducto, ProdDoc.idServicioDocumento)
          this._snakBar.open("Documento Eliminado!", "Aviso", { duration: 3000 })
          this.documentos.splice(index, 1)
        }
      } catch (err) {
        console.log(err);
        this._snakBar.open("Error al eliminar el Documento", "Error", { duration: 3000 })
      }
    })
  }


  addRequisito(ProdReq = null, index = -1) {
    const dialogRef = this._dialog.open(RequisitoComponent, {
      width: '1100px',
      data: { idServicio: this.idServicio, idServicioProducto: this.productoServicio.idServicioProducto, servicioProductoRequisito: ProdReq }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log("index", index);
        if (index >= 0) {
          this.requisitos[index] = result
        } else {
          this.requisitos.push(result)
        }
      }
      console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  async delRequisito(ProdReq, index) {
    // console.log(ProdReq);
    // let i = await this.requisitos.find(ProdReq)
    // console.log(i);
    const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title: "Eliminar", message: `Desea eliminar el requisito ${ProdReq.RequisitoFitosanitario.nombre}`, cancelButton: "Cancelar", okButton: "Aceptar"
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        if (result) {
          await this._servicioService.deleteServicioProductoReq(this.idServicio, this.productoServicio.idServicioProducto, ProdReq.idServicioProductoRequisito) as any
          this.requisitos.splice(index, 1)
          // (this.productoServicio.ServicioProductoRequisitos as Array<any>).splice(i, 1)
          this._snakBar.open("Requisito Eliminado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        console.log(err);
        this._snakBar.open("Error al eliminar el requisito", "Error", { duration: 3000 })
      }
    })
  }

  async actualizaProducto() {
    console.log(this.idServicio, this.productoServicio.idServicioProducto, this.servicioProductoForm.value);
    let resp = await this._servicioService.putServicioProducto(this.idServicio, this.productoServicio.idServicioProducto, this.servicioProductoForm.value)
    console.log(resp);
  }

  async listoAVerificar(event) {
    console.log(event.value)
    if (event.value == 0) {
      await this.showConfirmaCancelacion("Cancelacion", "Desea cancelar el servicio por Falta de condiciones?", "Regresar", "Proceder con Cancelacion")
    }
  }

  async showConfirmaCancelacion(title: string, message: string, cancelButton: string, okButton: string) {
    const dialogRef = this._confirmDialog.open(CancelarServicioComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title, message, cancelButton, okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          // this._servicioService.patchServicio(this.idServicio, { action: 3 })
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }
}
