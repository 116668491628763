import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';


import { DashboardRoutingModule } from './dashboard-routing.module';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
import { DashboardComponent } from './dashboard.component';
import { MainComponent } from './main/main.component';



@NgModule({
  declarations: [
    DashboardComponent,
    NavbarComponent,
    SidenavComponent,
    MainComponent,

  ],
  imports: [
    SharedModule,
    CommonModule,
    DashboardRoutingModule
  ]
})
export class DashboardModule { }
