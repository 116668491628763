import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';

@Injectable({
  providedIn: 'root'
})
export class UbicacionService {

  constructor(private _http: HttpClient,) { }

  async getUbicaciones(filter) {
    // let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[nombre][like]=%${nombre}%`
    // }
    return this._http.get(`${URL}/ubicacion?${filter}`)
      .toPromise()
  }

  async getUbicacion(id) {
    return this._http.get(`${URL}/ubicacion/${id}`)
      .toPromise()
  }

  async postUbicacion(post) {
    return this._http.post(`${URL}/ubicacion`, post)
      .toPromise()
  }

  async putUbicacion(id, put) {
    return this._http.put(`${URL}/ubicacion/${id}`, put)
      .toPromise()
  }

  async patchUbicacion(id, action, patch) {
    return this._http.patch(`${URL}/ubicacion/${id}`, { action, patch })
      .toPromise()
  }
}
