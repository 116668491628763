import { Injectable } from '@angular/core';
import { UserConfig } from '../../shared/classes/userConfig.class';
import { Subject, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { tap, map } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
//import { Profile } from '../../shared/classes/profile.class';
import { URL } from '../../config/url.services';


@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: UserConfig;
  profileLoaded = false;
  // profile: Profile = null;
  constructor(
    private _http: HttpClient,
    private _ss: StorageService,
    private _router: Router
  ) {
    this._ss.loadUsrConfig();
    if (this.isAuthenticated()) {
      this.user = { ...this._ss.usrConfig }
    }
  }
  public isAuthenticated(): boolean {
    let token = this._ss.getUsrPreference("token");
    if (token) return true;
    return false;
  }
  public setUserConfig(userData) {
    this._ss.loadUsrConfig();
    this.user = { ...userData }
  }

  ///////////////////////////////// Login
  async login(loginUser: any) {
    // return new Promise((resolve, reject) => {
    //   resolve({ ...loginUser, nombre: "Jhon Doe", token: "123", refresh: "123" })
    // }
    // )
    const response = await this._http.post(`${URL}/auth`, loginUser).toPromise();
    return response;
  }
  async logout(redirect: boolean = false) {
    const prom = new Promise((resolve, reject) => {
      try {
        //this.profile = null;
        this._ss.clearUsrConfig();
        this.profileLoaded = false;
        this.user = null;
        if (redirect) this._router.navigate(["/login"]);
        resolve(true);
      } catch (e) {
        reject(e);
      }
    });
    return prom;
  }

  refreshToken = () => {
    const { refresh, idUsuario } = this.user;
    if (!refresh) {
      return throwError(new Error("Token not found"));
    }
    return this._http.post(`${URL}/auth/refresh`, { refresh, idUsuario })
      .pipe(
        tap((refresh: any) => {
          this.user.token = refresh.token;
          this._ss.setUsrConfig(this.user);
        })
      )
  }
  /////////////////////////////////////////////
  //////////// Api Calls Misc
  async getUsers(nombre = "") {
    return this._http.get(`${URL}/usuario?filters[idRol][in]=4,6&filters[nombre][like]=%${nombre}%&filters[activo][eq]=1`)
      .toPromise()
  }
}
