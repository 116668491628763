import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';


@Injectable({
  providedIn: 'root'
})
export class UsuarioService {

  constructor(private _http: HttpClient) { }

  async getUsuarios(filter) {
    console.log(filter, URL);
    return this._http.get(`${URL}/usuario?${filter}`)
      .toPromise()
  }


  async getUsuario(id) {
    return this._http.get(`${URL}/usuario/${id}`)
      .toPromise()
  }

  async postUsuario(post) {
    return this._http.post(`${URL}/usuario`, post)
      .toPromise()
  }

  async putUsuario(id, put) {
    return this._http.put(`${URL}/usuario/${id}`, put)
      .toPromise()
  }

  async patchUsuario(id, action, patch) {
    return this._http.patch(`${URL}/usuario/${id}`, { action, patch })
      .toPromise()
  }

  async postUsuarioRegulacion(post) {
    console.log(`${URL}/usuario/${post.idUsuario}/regulacion`);
    return this._http.post(`${URL}/usuario/${post.idUsuario}/regulacion`, post)
      .toPromise()
  }

  async putUsuarioRegulacion(idUsuarioRegulacion, usuarioRegulacion) {
    return this._http.put(`${URL}/usuario/${usuarioRegulacion.idUsuario}/regulacion/${idUsuarioRegulacion}/`, usuarioRegulacion)
      .toPromise()
  }

  async deleteUsuarioRegulacion(idUsuarioRegulacion, idUsuario) {
    return this._http.delete(`${URL}/usuario/${idUsuario}/regulacion/${idUsuarioRegulacion}`)
      .toPromise()
  }

  async getRoles() {
    return this._http.get(`${URL}/rol`)
      .toPromise()
  }

  async getRegula() {
    return this._http.get(`${URL}/rol`)
      .toPromise()
  }
}
