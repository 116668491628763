import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/catalogos/usuario.service';

@Component({
  selector: 'app-add-usuario',
  templateUrl: './add-usuario.component.html',
  styleUrls: ['./add-usuario.component.sass']
})
export class AddUsuarioComponent implements OnInit {
  idUsuario
  roles = []
  constructor(private _snackBar: MatSnackBar, public matDialogRef: MatDialogRef<any>, private _us: UsuarioService, @Optional() @Inject(MAT_DIALOG_DATA) public data) {
    // console.log("entra a ID", this.data.id);
  }
  usuarioForm = new FormGroup({
    idRol: new FormControl(null, Validators.required),
    nombre: new FormControl(null, Validators.required),
    direccion: new FormControl(null, Validators.required),
    email: new FormControl(null, [Validators.required, Validators.email]),
    telefono: new FormControl(null, Validators.required),
    rfc: new FormControl(null, [Validators.required]),
  })
  async ngOnInit() {
    this.roles = await this._us.getRoles() as Array<any>
    if (this.data.id) {

      this.idUsuario = this.data.id
      let usuario = await this._us.getUsuario(this.data.id)
      console.log(usuario);
      this.usuarioForm.patchValue(usuario)
    }

  }

  async guardaUsuario() {

    if (this.usuarioForm.valid && !this.usuarioForm.pristine) {
      try {
        if (this.idUsuario) {
          this.putUsuario()
        }
        else {
          this.postUsuario()
        }
        this._snackBar.open("Informacion actualizada", "Aviso", { duration: 3000 })
        this.matDialogRef.close({ ...this.usuarioForm.value, idCliente: this.idUsuario });
      } catch (err) {
        this._snackBar.open("Error al guardar cliente", "Error", { duration: 3000 })
      }
    }
  }

  async postUsuario() {
    try {
      let usuario = await this._us.postUsuario(this.usuarioForm.value) as any
      this.idUsuario = usuario.idUsuario
    } catch (err) {
      throw err
    }
  }

  async putUsuario() {
    try {
      let usuario = await this._us.putUsuario(this.idUsuario, this.usuarioForm.value) as any
      // this.idUsuario = cliente.idCliente
    } catch (err) {
      throw err
    }
  }

  async disableUsuario() {
    try {
      let usuario = await this._us.patchUsuario(this.idUsuario, this.usuarioForm.value, "deactivate") as any
      // this.idUsuario = cliente.idCliente
    } catch (err) {
      throw err
    }
    this._snackBar.open("Usuario desactivado", "Aviso", { duration: 3000 })
    this.matDialogRef.close({ ...this.usuarioForm.value, idCliente: this.idUsuario });
  }

  async resetUsuario() {
    try {
      let usuario = await this._us.patchUsuario(this.idUsuario, this.usuarioForm.value, "reset") as any
      // this.idUsuario = cliente.idCliente
    } catch (err) {
      throw err
    }
    this._snackBar.open("Reseteo completo", "Aviso", { duration: 3000 })
    this.matDialogRef.close({ ...this.usuarioForm.value, idCliente: this.idUsuario });
  }
}
