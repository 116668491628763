import { Component, OnInit, Optional } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
// import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { ProductoComponent } from './producto/producto.component';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CiudadService } from '../../../services/catalogos/ciudad.service';
import { ClienteService } from '../../../services/catalogos/cliente.service';
import { UbicacionService } from '../../../services/catalogos/ubicacion.service';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { ActivatedRoute, Router } from '@angular/router';
// import { timeStamp } from 'console';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UtilitiesService } from '../../../services/utilities/utilities.service';
import { ClassDirective } from '@angular/flex-layout';
import { ConfirmDialogComponent } from '../../../shared/components/common/modals/confirm-dialog/confirm-dialog.component';
import { AddClienteComponent } from '../../shared/add-cliente/add-cliente.component';
import { AddUbicacionComponent } from '../../shared/add-ubicacion/add-ubicacion.component';


@Component({
  selector: 'app-registro',
  templateUrl: './registro.component.html',
  styleUrls: ['./registro.component.sass']
})
export class RegistroComponent implements OnInit {
  ciudades: Array<any>
  paises: Array<any>
  remitentes: Array<any>
  destinatarios: Array<any>
  clientes: Array<any>
  idServicio: String
  verificacion = [{ valor: 1, texto: "Certificado Fitosanitario para la Movilización Nacional" },
  { valor: 2, texto: "Certificado Fitosanitario Internacional" },
  { valor: 9, texto: "Otro" }]
  attributes: string


  productos = []
  servicioForm = new FormGroup({
    idServicio: new FormControl(null),
    lugarSolicitud: new FormControl(null, Validators.required),
    fechaSolicitud: new FormControl(new Date(), Validators.required),
    verificacion: new FormControl(null, Validators.required),
    movilidad: new FormControl(null),
    internacional: new FormControl(null),
    remitente: new FormControl(null, Validators.required),
    destinatario: new FormControl(null, Validators.required),
    idCliente: new FormControl(null, Validators.required),
    otroServicio: new FormControl()
  })
  constructor(private dialog: MatDialog,
    private _ciudadService: CiudadService,
    private _clienteService: ClienteService,
    private _ubicacionService: UbicacionService,
    private _servicioService: ServicioService,
    private _activatedRoute: ActivatedRoute, private _router: Router, private _snakBar: MatSnackBar,
    private _us: UtilitiesService, private _confirmDialog: MatDialog,
    private _snackBar: MatSnackBar) {
    this._activatedRoute.params.subscribe(params => {
      this.idServicio = params['idServicio'];
      console.log('Service #: ', this.idServicio);
    });
  }

  ngOnInit(): void {
    // console.log(Date.prototype.getDate())
    this.attributes = ""
    let sequencial = 0
    for (var key in this.servicioForm.controls) {
      console.log(`attributes[${sequencial}]=${key}&`)
      this.attributes = this.attributes.concat(`attributes[${sequencial}]=${key}&`)
      sequencial++
    }
    console.log(this.attributes)
    if (this.idServicio != "new") {
      this.getServicio(this.attributes)
      console.log(this.servicioForm.value);
    }
    // console.log(`/${this.idServicio}`);
  }

  abrirProducto(idServicioProducto = null) {
    if (this.idServicio != "new") {
      const dialogRef = this.dialog.open(ProductoComponent, {
        width: '1100px',
        data: { idServicio: this.idServicio, idServicioProducto }
      });

      dialogRef.afterClosed().subscribe(result => {
        // console.log('The dialog was closed');
        // this.animal = result;
        this.getServicio(this.attributes)
      });
    } else {
      this._snakBar.open("No  ha grabado el servicio", "Alerta", { duration: 5000 })
    }
  }

  abrirUbicaciones() {
    const dialogRef = this.dialog.open(AddUbicacionComponent, {
      width: '1100px',
      data: {}
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      // this.animal = result;
      //this.getServicio(this.attributes)
    });
  }

  async getDestinatarios(event) {
    let value = await this._us.eventToSearch(event)
    var result = <any>await this._ubicacionService.getUbicaciones(`filters[nombre][like]=%${value}%&filters[active][eq]=1`)
    this.destinatarios = result.rows
  }

  async getRemitentes(event) {
    let value = await this._us.eventToSearch(event)
    var result = <any>await this._ubicacionService.getUbicaciones(`filters[nombre][like]=%${value}%&filters[active][eq]=1`)
    this.remitentes = result.rows
  }

  async getClientes(event) {
    let value = await this._us.eventToSearch(event)
    var result = <any>await this._clienteService.getClientes(`filters[nombre][like]=%${value}%&filters[activo][eq]=1`)
    this.clientes = result.rows
    console.log(this.clientes);
  }

  async getCiudades(event) {
    let value = await this._us.eventToSearch(event)
    this.ciudades = <any>await this._ciudadService.getCiudad(`filters[nombre][like]=%${value}%`)
    // console.log(this.ciudades)
  }

  displayCiudad(ciudad) {
    return ciudad ? ciudad.nombre : ""
  }

  displayUbicacion(ubicacion) {
    return ubicacion ? ubicacion.nombre : ""
  }

  displayCliente(cliente) {
    return cliente ? cliente.nombre : ""
  }

  async guardaServicio() {
    console.log(this.servicioForm)
    if (this.servicioForm.valid) {
      let servicio = {
        lugarSolicitud: this.servicioForm.value.lugarSolicitud.idCiudad,
        fechaSolicitud: this.servicioForm.value.fechaSolicitud,
        verificacion: this.servicioForm.value.verificacion,
        movilidad: this.servicioForm.value.movilidad,
        internacional: this.servicioForm.value.internacional,
        remitente: this.servicioForm.value.remitente.idUbicacion,
        destinatario: this.servicioForm.value.destinatario.idUbicacion,
        idCliente: this.servicioForm.value.idCliente.idCliente,
        otroServicio: this.servicioForm.value.otroServicio
      }
      this.servicioForm.value
      // console.log(servicio)
      if (!this.servicioForm.pristine) {
        if (this.idServicio == "new") {
          this.postServicio(servicio)
        } else {

          this.putServicio(servicio)
        }
      }
      this._snackBar.open("Servicio almacenado", "Aviso", { duration: 3000 })
    } else {
      console.log("Not valid", this.servicioForm)
      this._snackBar.open("Error al registrar el servicio", "Error", { duration: 3000 })
    }
  }

  async postServicio(post) {
    let servicio = await this._servicioService.postServicio(post) as any
    this._router.navigate([`servicios/registro/${servicio.idServicio}`])
  }

  async putServicio(put) {
    let servicio = await this._servicioService.putServicio(this.idServicio, put) as any
  }

  async deleteProducto(servicioProducto) {
    await this.showConfirmDialog("Eliminar", `Desea eliminar el producto ${servicioProducto.Producto.descripcion} seleccionado?`, "Cancelar", "Eliminar", servicioProducto)

    // let del = await this._servicioService.deleteServicioProducto(this.idServicio, idServicioProducto)
  }
  async showConfirmDialog(title: string, message: string, cancelButton: string, okButton: string, servicioProducto) {
    const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title, message, cancelButton, okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        // return result
        if (result) {
          // console.log(servicioProducto);

          await this._servicioService.deleteServicioProducto(this.idServicio, servicioProducto.idServicioProducto)
          this._snakBar.open("Producto eliminado", "Aviso", { duration: 3000 })

          await this.getServicio(this.attributes)
        }
      } catch (err) {
        console.log(err);
        this._snakBar.open("Error al eliminar producto", "Error", { duration: 3000 })
      }
    })

  }
  async getServicio(attributes) {
    let servicio = await this._servicioService.getServicio(this.idServicio, attributes) as any
    this.ciudades = [servicio.LugarSolicitud]// await this._ciudadService.getCiudad(`filters[idCiudad][eq]=${servicio.lugarSolicitud}`) as any
    this.remitentes = [servicio.Remitente]// await this._ubicacionService.getUbicaciones(`filters[idUbicacion][eq]=${servicio.remitente}`) as any
    this.destinatarios = [servicio.Destinatario]// await this._ubicacionService.getUbicaciones(`filters[idUbicacion][eq]=${servicio.destinatario}`) as any
    this.clientes = [servicio.Cliente]// await this._clienteService.getClientes(`filters[idCliente][eq]=${servicio.idCliente}`) as any
    console.log(servicio);
    // this.verificacion = servicio.verificacion
    servicio.lugarSolicitud = this.ciudades[0]
    servicio.destinatario = this.destinatarios[0]
    servicio.remitente = this.remitentes[0]
    servicio.idCliente = this.clientes[0]
    this.servicioForm.patchValue(servicio)
    this.productos = servicio.ServicioProductos
    // console.log(this.productos);
    console.log("verificacion", this.servicioForm.value.verificacion);
  }

  agregaCliente() {
    const dialogRef = this._confirmDialog.open(AddClienteComponent, {
      panelClass: "sixtyprc-dialog",
      // disableClose: true,
      // maxHeight: '40%',
      data: null
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        if (result) {
        }
      } catch (err) {
        console.log(err);
        this._snakBar.open("Error al agregar cliente", "Error", { duration: 3000 })
      }
    })
  }

  async verReporte() {
    // let servicio = await this._servicioService.postServicio(post) as any
    console.log("Ver Reporte");
    this._router.navigate([`servicios`])
  }

}
