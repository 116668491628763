<div class="app-container">
    <form [formGroup]="asignaServicio" (ngSubmit)="asigna()">
        <div class="row">
            <div class="title center-align col l12 m12 s12">
                <span>Asigna servicio</span>
            </div>
        </div>
        <div class=row>
            <div class="col l12 m12 s12">
                <mat-form-field appearance="fill">
                    <mat-label>TEF</mat-label>
                    <input type="text" placeholder="Escoge TEF" aria-label="Number" matInput formControlName="asignadoA"
                        (input)="getUsuario($event)" [matAutocomplete]="usuario">
                    <mat-autocomplete autoActiveFirstOption #usuario="matAutocomplete" [displayWith]="displayUsuario">
                        <mat-option *ngFor="let usuario of users" [value]="usuario">
                            {{usuario.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col l12 m12 s12">
                <mat-form-field appearance="fill">
                    <mat-label>Fecha Programado</mat-label>
                    <input matInput [matDatepicker]="datepicker" [min]="minDate" formControlName="fechaProgramacion">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                    </mat-datepicker>
                </mat-form-field>
            </div>
        </div>

        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Agregar
            </button>
        </div>
    </form>
</div>