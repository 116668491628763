import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { merge, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ClienteService } from 'src/app/services/catalogos/cliente.service';
import { FoliosService } from 'src/app/services/catalogos/folios.service';
import { TableComponent } from 'src/app/shared/components/common/tables/table/table.component';
import { AddFoliosComponent } from '../add-folios/add-folios.component';
import { FormControl } from '@angular/forms';


@Component({
  selector: 'app-lista-folios',
  templateUrl: './lista-folios.component.html',
  styleUrls: ['./lista-folios.component.sass']
})
export class ListaFoliosComponent implements OnInit {

  activo = new FormControl(true);
  isLoading = true;
  usuarioFolios;

  displayedColumns = ["Acciones", "Usuario", "TipoFolio", "Año", "Ini", "Fin", "Actual", "Activo"]; //"patente", "aduana", "pedimento", "eta"
  vals = [
    { nombreColumna: "Acciones", campo: "", tipoChip: false },
    { nombreColumna: "Usuario", campo: "Usuario", tipoChip: false },
    { nombreColumna: "TipoFolio", campo: "tipoFolio", tipoChip: false },
    { nombreColumna: "Año", campo: "anio", tipoChip: false },
    { nombreColumna: "Ini", campo: "ini", tipoChip: false },
    { nombreColumna: "Fin", campo: "fin", tipoChip: false },
    { nombreColumna: "Actual", campo: "actual", tipoChip: false },
    { nombreColumna: "Activo", campo: "activo", tipoChip: true },
  ];

  status = [{ id: '0', nombre: 'Inactivo', color: 'gray' }, { id: '1', nombre: 'Activo', color: 'green' }]

  getStatus(tipo, id) {
    if (tipo == "activo") {
      return this.status.find(st => st.id == id);
    }
  }
  obtenerValor(row) {
    if (typeof row === 'object' && row !== null) {
      return row.nombre;//[val.campo]
    }
    else {
      return row;
    }
  }
  getObj(value) {
    return this.vals.find(val => val.nombreColumna === value);
  }
  AbrirMenu() {
    this.trigger.openMenu();
  }

  resultsLength = 0;
  pageSize = 10;
  searchBy = { value: "nombre", text: 'Usuario' }
  attributes = 'attributes[0]=idUsuarioFolio&attributes[1]=idUsuario&attributes[2]=tipoFolio&attributes[3]=ini&attributes[4]=fin&attributes[5]=actual&attributes[6]=activo&attributes[7]=anio&attributes[8]=activo';
  filters = '' // 'filters[status][in]=1,0&filters[fechaSolicitud][between]=2021-08-01,2021-09-12';
  busquedasDispoible = [];

  searchOptions: any[] = [{ value: "fase", text: 'Fase' },
  { value: "movilidad", text: 'Movilidad' },
  { value: "internacional", text: 'Internacional' },
  { value: "otroServicio", text: 'Otro Servicio' },
  ];
  panelOpenState = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() resultadoReporte;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(TableComponent, { static: false }) public table: TableComponent<any>;
  constructor(private _fs: FoliosService, private _router: Router, private _dialog: MatDialog) { }

  ngOnInit(): void {
    // console.log(this.resultadoReporte);
    this.displayedColumns = [...this.displayedColumns];
    // this.servicios = this.resultadoReporte;

    console.log("Esta es la lista de servicios");
  }

  ngAfterViewInit() {
    this.actualizarReporte()
  }
  handleSearchChange(event) {
    console.log("Busqueda del reporte");
  }
  verMas(element) {
    console.log("Ver mas");
  }
  actualizarReporte() {
    merge(this.table.sort.sortChange, this.table ? this.table.paginator.page : [], this.table.searchValue.valueChanges.pipe(debounceTime(500)), this.activo.valueChanges)
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          // let chipsFilters = this.agregarFiltros();
          return this._fs.getFolios(`${this.attributes}&${this.filters}&size=${this.table.paginator.pageSize}&page=${this.table.paginator.pageIndex}&filters[activo][eq]=${this.activo.value ? '1' : '0'}`);
        }),
        map((data: any) => {
          this.isLoading = false;
          this.resultsLength = data.count;
          return data.rows
          //return [];
        }),
        catchError(() => {
          this.isLoading = false;

          return of([]);
        })
      )
      .subscribe((data: any) => {
        if (data) {
          // this._ss.setPreference("pageSize", this.table.paginator.pageSize.toString());
          this.usuarioFolios = data;
          // this.getFiltros();

        } else {
          this.usuarioFolios = [];
        }
      });
  }

  verifica(id) {
    // console.log(id)
    // console.log(`verificacion/${id}`);
    // this._router.navigate([`servicios/verificacion/${id}`])
  }

  edita(usuarioFolio) {
    // console.log(id)
    // console.log(`editar/${id}`);
    // this._router.navigate([`servicios/registro/${id}`])
    this.showAgregaCliente(usuarioFolio)
  }

  async activarCancelarFolio(folio, value) {
    try {
      this.isLoading = true;
      let folioPut = { ...folio };
      folioPut.activo = value;
      let data = await this._fs.putFolios(folioPut, folioPut.idUsuarioFolio);
      this.actualizarReporte()
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  }

  asigna(servicio) {
  }

  imprimir(idServicio) {
  }

  agregaUsuario() {
    // this._router.navigate([`servicios/registro/new`])

  }



  async showAgregaCliente(UsuarioFolio = null) {
    const dialogRef = this._dialog.open(AddFoliosComponent, {
      // panelClass: "sixtyprc-dialog",
      disableClose: false,
      maxHeight: '60%',
      data: { UsuarioFolio }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          this.actualizarReporte()
          // this._servicioService.patchServicio(this.idServicio, { action: 3 })
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }

}
