import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login/login/login.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { AuthGuard } from './services/guard/auth.guard';
import { RegistroComponent } from './modules/servicios/registro/registro.component';
// import { VerificacionComponent } from './modules/servicios/verificacion/verificacion.component';
import { ReportesComponent } from './modules/reportes/reportes.component';
import { ServiciosComponent } from './modules/servicios/servicios.component';
import { CatalogosModule } from './modules/catalogos/catalogos.module';
import { CatalogosComponent } from './modules/catalogos/catalogos.component';

const routes: Routes = [
  { path: "login", component: LoginComponent },
  { path: "dashboard", component: DashboardComponent, canActivate: [AuthGuard], loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: "servicios", component: ServiciosComponent, canActivate: [AuthGuard], loadChildren: () => import('./modules/servicios/servicios.module').then(m => m.ServiciosModule) },
  { path: "reportes", component: ReportesComponent, canActivate: [AuthGuard], loadChildren: () => import('./modules/reportes/reportes.module').then(m => m.ReportesModule) },
  { path: "catalogos", component: CatalogosComponent, canActivate: [AuthGuard], loadChildren: () => import('./modules/catalogos/catalogos.module').then(m => m.CatalogosModule) },
  { path: '', redirectTo: 'servicios', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
