import { Component, OnInit, AfterContentInit, ContentChildren, QueryList, ViewChild, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { MatHeaderRowDef, MatRowDef, MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';


import { FormControl } from '@angular/forms';
import { MatSort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Subscription } from 'rxjs';



@Component({
  selector: 'app-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass']
})
export class TableComponent<T> implements AfterContentInit, OnInit, OnDestroy {

  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @Input() minWidth: 1800;
  @Input() columns: string[];
  @Input() public sort: MatSort;
  @Input() dataSource: MatTableDataSource<T>;
  @Input() count: number = 0;
  @Input() pageSize: number = 10;
  @Input() showFirstLastButtons = true;
  @Input() sortActive: string = "";
  @Input() isFetching: boolean = false;
  @Input() showFilters: boolean = true;
  @Input() pageOptions;
  @Input() heigth;
  @Output() onPageChange: EventEmitter<number>;
  @Output() onAdvancedSearch: EventEmitter<any>;

  showSearchBar = false;
  public searchValue = new FormControl();
  searchValue$: Subscription;
  @Input() searchLabel: string = "Buscar";
  @Output() onSearchClosed: EventEmitter<any>;
  @Input() title: string;
  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => {
      this.table.addColumnDef(columnDef);

    });
    this.rowDefs.forEach(rowDef => {
      this.table.addRowDef(rowDef)
    });
    this.headerRowDefs.forEach(headerRowDef => {
      this.table.addHeaderRowDef(headerRowDef);
    })
    if (this.dataSource) {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;
    }
  }
  constructor() {
    this.onSearchClosed = new EventEmitter();
    this.onPageChange = new EventEmitter();
  }
  ngOnDestroy(): void {
    if (this.searchValue$) this.searchValue$.unsubscribe();
  }
  ngOnInit(): void {
    this.searchValue$ = this.searchValue.valueChanges.subscribe(value => {
      if (this.paginator.pageIndex > 0) this.paginator.pageIndex = 0;
    })
  }
  handleSearchClosed() {
    this.searchValue.setValue("");
    this.showSearchBar = false;
    this.onSearchClosed.emit();
  }

}
