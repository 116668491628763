import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-transporte',
  templateUrl: './transporte.component.html',
  styleUrls: ['./transporte.component.sass']
})
export class TransporteComponent implements OnInit {
  idServicio: string
  @Input() servicioVerificacion: any
  estadosTransporte = ["Sin Residuos vegetales o de cosecha", "Sin Residuos de suelo", "Lavado", "Limpio Completamente"]
  tiposTransporte = ["Refrigerado", "Enlonado", "Caja Seca", "Otro"]
  transporteForm = new FormGroup({
    estadoTransporte: new FormControl('', Validators.required),
    tipoTransporte: new FormControl('', Validators.required),
    otroTipoTransporte: new FormControl('')
  })
  constructor(private _servicioService: ServicioService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _utilitiesService: UtilitiesService,
    private _snakBar: MatSnackBar) {
    this._activatedRoute.params.subscribe(params => {
      this.idServicio = params['idServicio'];
      console.log('Service #: ', this.idServicio);
    });
  }

  ngOnInit(): void {
    console.log(this.servicioVerificacion);
    let forma = this._utilitiesService.castObjetoAsProperties(Object.keys(this.transporteForm.controls), this.servicioVerificacion)
    // console.log(forma);
    this.transporteForm.setValue(forma)
  }

  async actualiza() {
    if (this.transporteForm.valid && !this.transporteForm.pristine) {
      try {
        await this.putTransporte(this.transporteForm.value)
        this.transporteForm.markAsPristine
        // this.putTransporte(this.transporteForm)
        this._snakBar.open("Servicio actualizado!", "Aviso", { duration: 3000 })
      } catch (err) {
        // console.log(err);
        this._snakBar.open("Error al actualizar!" + err, "Error", { duration: 3000 })
      }

    }
  }

  async putTransporte(put) {
    console.log(this.idServicio, put);
    let servicio = await this._servicioService.putTransporteServicio(this.idServicio, put) as any
  }
}
