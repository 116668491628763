import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { CatalogosService } from '../../../../../services/catalogos/catalogos.service';

@Component({
  selector: 'app-documento',
  templateUrl: './documento.component.html',
  styleUrls: ['./documento.component.sass']
})
export class DocumentoComponent implements OnInit {
  idServicioDocumento
  documentoForm = new FormGroup({
    idDocumento: new FormControl(null, Validators.required),
    numero: new FormControl(null, Validators.required)
  })
  documentos = []
  constructor(private _cs: CatalogosService,
    @Inject(MAT_DIALOG_DATA) public data,
    private _ss: ServicioService,
    private _snackBar: MatSnackBar,
    private _us: UtilitiesService,
    public matDialogRef: MatDialogRef<DocumentoComponent>) { }

  async ngOnInit() {
    this.documentos = <any>await this._cs.getDocumentos()
    if (this.data.servicioProductoDocumento) {
      this.data.servicioProductoDocumento
      this.idServicioDocumento = this.data.servicioProductoDocumento["idServicioDocumento"]
      let forma = this._us.castObjetoAsProperties(Object.keys(this.documentoForm.controls), this.data.servicioProductoDocumento)
      console.log(forma);
      this.documentoForm.setValue(forma)
    }
  }

  async agregaDocumento() {
    try {
      let prodDoc: any
      if (this.documentoForm.valid && !this.documentoForm.pristine) {
        if (!this.idServicioDocumento) {
          prodDoc = this.documentoForm.value
          await this._ss.postServicioProductoDoc(this.data.idServicio, this.data.idServicioProducto, this.documentoForm.value) as any
          this.idServicioDocumento = prodDoc.idServcioProductoDocumento
        } else {
          prodDoc = this.documentoForm.value
          await this._ss.putServicioProductoDoc(this.data.idServicio, this.data.idServicioProducto, this.idServicioDocumento, this.documentoForm.value)
        }
        this._snackBar.open("Documento actualizado", "Aviso", { duration: 3000 })
        // console.log("Documento", prodDoc, "Docs", this.documentos);
        prodDoc.Documento = { nombre: this.documentos.find(x => x.idDocumento == prodDoc.idDocumento).nombre }

        this.matDialogRef.close(prodDoc);
      }
    } catch (err) {
      this._snackBar.open("No se pudo actualizar el documento", "Error", { duration: 3000 })
    }
  }

}
