<div class="mat-elevation-z8">
    <div class="table-toolbar">
        <div class="search-bar-container animated fadeIn fast" *ngIf="showSearchBar">
            <mat-icon class="search-icon">search</mat-icon>
            <div class="search-bar-wrapper d-flex">
                <mat-form-field appearance="standard" color="accent">
                    <input *ngIf="showSearchBar" matInput [placeholder]="searchLabel" autocomplete="off" #searchTerm [formControl]="searchValue" appAutofocus />
                </mat-form-field>
            </div>
            <button mat-icon-button type="button" (click)="handleSearchClosed()">
                <mat-icon>close</mat-icon>
            </button>
            <ng-content class="advanced-search" select=".advancedSearch">
            </ng-content>
        </div>
        <div class="header-title-wrapper animated fadeIn fast" *ngIf="!showSearchBar"> {{ title }} </div>
        <span class="spacer"></span>
        <div class="header-buttons">
            <button mat-icon-button type="button" (click)="showSearchBar = !showSearchBar">
                <mat-icon>search</mat-icon>
            </button>
            <button *ngIf="filters" mat-icon-button matTooltip="Filtros" type="button" #popoverTrigger2="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover2"
                mtxPopoverTriggerOn="click" class="gray-icon">
                <mat-icon>filter_list</mat-icon>
            </button>
            <mtx-popover
                #popover2="mtxPopover"
                xPosition="before"
                yPosition="below"
                [xOffset]="0"
                [yOffset]="0"
                [overlapTrigger]="false"
                [closeOnPanelClick]="false"
                [closeOnBackdropClick]="true">
                <app-table-filter [showReset]="true" (onReset)="resetFiltro()">
                    <div class="filter-body">
                        <div class="row mb0">
                            <div *ngFor="let filter of filters" class="col s6">
                                <mat-form-field appearance="fill">
                                    <mat-label>{{filter.name}}</mat-label>
                                    <mat-select name="{{filter.column}}" [(ngModel)]="filter.modelValue" (selectionChange)="filterChange(filter,$event)">
                                        <mat-option value="">Todo</mat-option>
                                        <mat-option [value]="item" *ngFor="let item of filter.options">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                    </div>
                </app-table-filter>
            </mtx-popover>
            <ng-content class="buttons" select=".buttons"> </ng-content>
        </div>
    </div>
    <div class="filter-row" *ngIf="filterValues">

        <mat-chip-list>
            <mat-chip [removable]="true" (removed)="removeFiltro(filter, i)" *ngFor="let filter of filterValues; let i = index;">
                {{filter.value | uppercase}}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>

    </div>
    <div class="progress-container">
        <mat-progress-bar *ngIf="isFetching" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="table-container-overflow">
        <table [ngStyle]="{'min-width.px': minWidth}" mat-table matSort [dataSource]="dataSource" [matSortActive]="sortActive" matSortDirection="asc" class="animated fadeIn">
            <ng-content></ng-content>
            <ng-container *ngIf="showNoMatch ">
                <ng-container *ngIf="!isFetching ">
                    <tr class="mat-row" *matNoDataRow>
                        <td class="mat-cell" [attr.colspan]="columns?.length || 0">
                            <span class="no-data"> No se encontró información</span>
                        </td>
                    </tr>
                </ng-container>
            </ng-container>
            <!-- <div class="no-data" *matNoDataRow>No se encontró información</div> -->
        </table>
    </div>

    <mat-paginator [showFirstLastButtons]="showFirstLastButtons" [pageSize]="pageSize"></mat-paginator>
</div>
