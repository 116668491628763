<form [formGroup]="transporteForm" (ngSubmit)="actualiza()">
    <div class="row">
        <div class="col s12 l8 m12">
            <label id="estadoTransporte">Estado del transporte</label>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Estado transporte</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="estadoTransporte">
                        <mat-option *ngFor="let estado of estadosTransporte" [value]="estado">
                            {{estado}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div class="col s12 l8 m12">
            <label id="tipoTransporte">Tipo de transporte</label>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Tipo transporte</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="tipoTransporte">
                        <mat-option *ngFor="let tipo of tiposTransporte" [value]="tipo">
                            {{tipo}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <div *ngIf="transporteForm.value.tipoTransporte=='Otro'" class="col s12 l6 m6">
            <mat-form-field appearance="fill">
                <mat-label>Descripcion Transporte</mat-label>
                <input matInput placeholder="" formControlName="otroTipoTransporte">
            </mat-form-field>
        </div>
        <div class="col s12 l9 m9">
            <button type="submit" class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Actualizar
            </button>
        </div>
    </div>
</form>