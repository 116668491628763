import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';

@Component({
  selector: 'app-asignar-servicio',
  templateUrl: './asignar-servicio.component.html',
  styleUrls: ['./asignar-servicio.component.sass']
})
export class AsignarServicioComponent implements OnInit {
  users: Array<any>
  minDate = new Date()
  asignaServicio = new FormGroup(
    {
      asignadoA: new FormControl(null, Validators.required),
      fechaProgramacion: new FormControl(null, Validators.required)
    })
  constructor(@Inject(MAT_DIALOG_DATA) private data, private _us: UserService, private _uts: UtilitiesService, private _ss: ServicioService, private _sb: MatSnackBar, private datePipe: DatePipe) { }


  ngOnInit(): void {
    this.obtieneTEF()
  }
  asigna() {
    try {
      if (this.asignaServicio.valid && !this.asignaServicio.pristine) {
        console.log({ action: 1, fields: this.asignaServicio.value })
        this._ss.patchServicio(this.data.servicio.idServicio,
          {
            action: 1,
            fields: { asignadoA: this.asignaServicio.value.asignadoA.idUsuario, fechaProgramacion: this.asignaServicio.value.fechaProgramacion }
          }).then(serv => {
            // console.log(serv);
            this._sb.open("Servicio Asignado", "Asignacion", { duration: 3000 })
          }).catch(err => {
            console.log(err);
            this._sb.open("Error de Asignacon: " + err.error, "Asignacion", { duration: 3000 })
          })
      }
    } catch (err) {

    }

  }

  async getUsuario(event) {
    let value = await this._uts.eventToSearch(event)
    // console.log(value)
    await this.obtieneTEF(value)
  }

  async obtieneTEF(nombre = "") {

    console.log(nombre);
    let result = await this._us.getUsers(nombre) as any
    this.users = result.rows
  }

  displayUsuario(value) {
    return value ? value.nombre : ""
  }
}
