import { Component, OnInit, Inject, NgZone, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { RegistroComponent } from '../../servicios/registro/registro.component';

import { MapsAPILoader } from '@agm/core';
import { UbicacionService } from 'src/app/services/catalogos/ubicacion.service';
import { ThrowStmt } from '@angular/compiler';

@Component({
  selector: 'app-add-ubicacion',
  templateUrl: './add-ubicacion.component.html',
  styleUrls: ['./add-ubicacion.component.sass']
})
export class AddUbicacionComponent implements OnInit {

  ubicacionesForm = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    direccion: new FormControl(null, Validators.required),
    latitud: new FormControl(null, Validators.required),
    longitud: new FormControl(null, Validators.required),
  });

  latitude: number;
  longitude: number;
  direccion
  zoom: number;
  address: string;
  private geoCoder;
  //googleMapType = 'satellite';

  async guardaUbicacion() {
    let nombre = this.ubicacionesForm.get('nombre').value;
    let direccion = this.ubicacionesForm.get('direccion').value;
    let latitud = this.ubicacionesForm.get('latitud').value;
    let longitud = this.ubicacionesForm.get('longitud').value;
    let respuestaUbicacionWS
    if (!this.data?.idUbicacion) {
      respuestaUbicacionWS = await this._us.postUbicacion({ nombre, direccion, latitud, longitud, active: 1 }) as any
    } else {
      respuestaUbicacionWS = await this._us.putUbicacion(this.data.idUbicacion, { nombre, direccion, latitud, longitud, active: 1 }) as any
    }

    if (respuestaUbicacionWS) {
      this._snackBar.open("Ubicacion agregada correctamente", "Success", { duration: 3000 })
      this.matDialogRef.close({});
    }

  }

  @ViewChild('search')
  public searchElementRef: ElementRef;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<RegistroComponent>,
    private mapsAPILoader: MapsAPILoader,//Consumir api de google maps, y obtener funciones y propiedades de
    private ngZone: NgZone, //Para obtener informacion sobre la ubicacion actual
    private _us: UbicacionService
  ) {
    if (this.data)
      this.ubicacionesForm.patchValue(this.data)
  }

  ngOnInit(): void {
    this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation();
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement);
      autocomplete.addListener("place_changed", () => {
        this.ngZone.run(() => {
          let place: google.maps.places.PlaceResult = autocomplete.getPlace();

          if (place.geometry === undefined || place.geometry === null) {
            return;
          }

          this.latitude = place.geometry.location.lat();
          this.longitude = place.geometry.location.lng();
          this.direccion = place.formatted_address
          // console.log(place);
          this.zoom = 12;
        });

        this.ubicacionesForm.get('latitud').setValue(this.latitude);
        this.ubicacionesForm.get('longitud').setValue(this.longitude);
        if(this.direccion) this.ubicacionesForm.get('direccion').setValue(this.direccion);
      });
    });
  }

  private setCurrentLocation() {

    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        // console.log(this.data.latitud, position.coords.latitude);
        this.latitude = this.data ? Number(this.data.latitud) : position.coords.latitude;
        this.longitude = this.data ? Number(this.data.longitud) : position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude, this.longitude);
      });
    }
  }

  getAddress(latitude, longitude) {
    this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results, status) => {
      if (status === 'OK') {
        if (results[0]) {
          this.zoom = 12;
          this.address = results[0].formatted_address;
        } else {
          window.alert('No results found');
        }
      } else {
        window.alert('Geocoder failed due to: ' + status);
      }

    });
  }

}
