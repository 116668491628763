<form [formGroup]="usuarioRegulacionForm" (ngSubmit)="guardaRegulacion()">
    <div class="row">
        <div class="title center-align col l12 m12 s12">
            <span>Regulaciones de {{data.id.nombre}}</span>
        </div>
    </div>
    <div class="row">
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Regulacion</mat-label>
                <mat-select autoActiveFirstOption formControlName="idRegulacion">
                    <mat-option *ngFor="let regulacion of regulaciones" [value]="regulacion.idRegulacion" [disabled]="regulacionesAsignadas.includes(regulacion.idRegulacion)">
                        {{regulacion.descripcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Vigencia</mat-label>
                <input matInput [matDatepicker]="datepicker" formControlName="fechaVencimiento">
                <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                <mat-datepicker #datepicker>
                </mat-datepicker>
            </mat-form-field>
        </div>
        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Guardar
            </button>
        </div>
    </div>
</form>