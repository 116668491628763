import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { FoliosService } from 'src/app/services/catalogos/folios.service';
import { UserService } from 'src/app/services/user/user.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-add-folios',
  templateUrl: './add-folios.component.html',
  styleUrls: ['./add-folios.component.sass']
})
export class AddFoliosComponent implements OnInit {

  foliosForm = new FormGroup({
    Usuario: new FormControl(null, Validators.required),
    anio: new FormControl(null, Validators.required),
    tipoFolio: new FormControl("RPI", Validators.required),
    ini: new FormControl(null, Validators.required),
    fin: new FormControl(null, Validators.required)
  })
  ini
  users = [];

  anios = []
  tipos = ["RPI"]


  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private _fp: FoliosService,
    private _snackBar: MatSnackBar, private matDialogRef: MatDialogRef<any>, private _confirmDialog: MatDialog,
    private _uts: UtilitiesService, private _us: UserService) {
    // if (this.data) {
    //   this.productosForm.patchValue({ idProducto: this.data.idProducto, descripcion: this.data.descripcion })
    //   this.Usos = JSON.parse(this.data.usos)
    //   console.log(this.data.presentacion);
    //   this.Presentaciones = JSON.parse(this.data.presentacion)
    // }
    this.anios[0] = new Date().getFullYear();
    if (new Date().getMonth() == 11) {
      this.anios[1] = new Date().getFullYear() + 1;
    }

  }


  async ngOnInit() {
    if (this.data.UsuarioFolio) {
      this.foliosForm.patchValue(this.data.UsuarioFolio)
    }
  }

  async getConsecutivo(event) {
    let folios = await this._fp.getFolios(`filters[anio][eq]=${this.foliosForm.value.anio}&size=1&sort=fin,desc`) as any
    console.log(folios);
    if (folios.rows.length > 0) {
      // console.log();

      this.foliosForm.controls["ini"].setValue(folios.rows[0].fin + 1)
      this.foliosForm.controls["ini"].disable()
      this.ini = folios.rows[0].fin + 1
    }
    else {
      this.foliosForm.controls["ini"].setValue(1)

    }
    //  console.log(folios);
  }


  async guardaFolio() {
    try {
      let fields = {
        idUsuario: this.foliosForm.value.Usuario.idUsuario,
        tipoFolio: this.foliosForm.value.tipoFolio,
        ini: this.ini ? this.ini : this.foliosForm.value.ini,
        fin: this.foliosForm.value.fin,
        anio: this.foliosForm.value.anio
      };
      console.log(this.foliosForm.value, fields);
      let respuestaProductoWs = await this._fp.postFolios(fields) as any
      if (respuestaProductoWs) {
        this._snackBar.open("Folios asignados correctamente", "Success", { duration: 3000 })
        this.matDialogRef.close(true);
      }
    } catch (err) {
      throw err
    }
  }

  async showConfirmaFolios() {
    let ini = this.ini ? this.ini : this.foliosForm.value.ini
    if (this.foliosForm.valid && !this.foliosForm.pristine) {
      if (ini < this.foliosForm.value.fin) {
        console.log("Confirma flios");
        const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
          panelClass: "sixtyprc-dialog",
          disableClose: true,
          maxHeight: '60%',
          data: {
            confirmDialogData: {
              title: "Asignacion de Folios", message: `Desea confirmar la asignacion del folio ${ini} al ${this.foliosForm.value.fin} a 
              ${this.foliosForm.value.Usuario.nombre} `, cancelButton: "Cancel", okButton: "Aceptar"
            }
          }
        });
        dialogRef.afterClosed().subscribe(async result => {
          try {
            if (result) {
              await this.guardaFolio()
            }
          } catch (err) {
            console.log(err);
            this._snackBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
          }
        })
      } else {
        this._snackBar.open("Valores incorrectos de inico / fin", "Error", { duration: 3000 })
      }
    }

  }

  async getUsuario(event) {
    let value = await this._uts.eventToSearch(event)
    // console.log(value)
    await this.obtieneTEF(value)
  }

  async obtieneTEF(nombre = "") {

    console.log(nombre);
    var result = await this._us.getUsers(nombre) as any
    this.users = result.rows
  }

  displayUsuario(value) {
    // this.nombeUsuario = value ? value.nombre : ""
    return value ? value.nombre : ""
  }
}
