import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { URL } from 'src/app/config/url.services';



@Injectable({
  providedIn: 'root'
})
export class CatalogosService {

  constructor(
    private _http: HttpClient,
    // private _ss: StorageService,
    // private _router: Router
  ) {

  }

  async getRegulaciones() {

    return this._http.get(`${URL}/regulacion`)
      .toPromise()
  }

  async getDocumentos() {

    return this._http.get(`${URL}/documento`)
      .toPromise()
  }

  async getRequisito() {

    return this._http.get(`${URL}/requisitofitosanitario`)
      .toPromise()
  }

  async getCiudades() {

    return this._http.get(`${URL}/ciudad`)
      .toPromise()
  }

  async getClientes() {

    return this._http.get(`${URL}/requisitofitosanitario`)
      .toPromise()
  }
}
