<form [formGroup]="requisitoForm" (ngSubmit)="agregaRequisito()">
    <div class=" title center-align col l12 m12 s12">
        <span>Documentos</span>
    </div>
    <div class="row">
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Requisito</mat-label>
                <mat-select autoActiveFirstOption formControlName="idRequisito">
                    <mat-option *ngFor="let requisito of requisitos" [value]="requisito.idRequisito">
                        {{requisito.nombre}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12" *ngIf="requisitoForm.value.idRequisito==13">
            <mat-form-field appearance="fill">
                <mat-label>Otro</mat-label>
                <input matInput formControlName="otroDesc">
            </mat-form-field>
        </div>
        <div class="col s12 l12 m12">
            <!-- <label id="cumplimiento">Cumple</label> -->
            <mat-form-field appearance="fill">
                <mat-label>Cumplimiento</mat-label>
                <mat-select autoActiveFirstOption formControlName="cumplimiento">
                    <mat-option *ngFor="let opcion of cumple" [value]="opcion.valor">
                        {{opcion.texto}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s6 l6 m6">
            <mat-form-field appearance="fill">
                <mat-label>Tipo Valor</mat-label>
                <mat-select autoActiveFirstOption formControlName="porcentaje">
                    <mat-option *ngFor="let opcion of porcentaje" [value]="opcion.valor">
                        {{opcion.texto}}
                    </mat-option>
                    <mat-option value=0>
                        Cantidad
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l6 m6 s6">
            <mat-form-field appearance="fill">
                <mat-label>Cantidad</mat-label>
                <input matInput formControlName="cantidad">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Observaciones</mat-label>
                <input matInput formControlName="observaciones">
            </mat-form-field>
        </div>
        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Agregar
            </button>
        </div>
    </div>
</form>