import { Component, OnInit, ContentChildren, ViewChild, Input, QueryList, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { MatHeaderRowDef, MatRowDef, MatColumnDef, MatTable, MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { FormControl } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-sync-table',
  templateUrl: './sync-table.component.html',
  styleUrls: ['./sync-table.component.sass']
})
export class SyncTableComponent<T> implements OnInit {


  @ContentChildren(MatHeaderRowDef) headerRowDefs: QueryList<MatHeaderRowDef>;
  @ContentChildren(MatRowDef) rowDefs: QueryList<MatRowDef<T>>;
  @ContentChildren(MatColumnDef) columnDefs: QueryList<MatColumnDef>;

  @ViewChild(MatTable, { static: true }) table: MatTable<T>;
  @ViewChild(MatPaginator, { static: true }) public paginator: MatPaginator;
  @Input() minWidth: 1800;
  @Input() columns: string[];
  @Input() sort: MatSort;

  @Input() showNoMatch = true;
  _data: Array<T> = [];
  dataSource: MatTableDataSource<T>;
  count: number = 0;
  @Input() pageSize: number = 10;
  @Input() showFirstLastButtons = true;
  @Input() sortActive: string = "";
  @Input() isFetching: boolean = false;
  @Input() filters;
  filterValues = [];
  showSearchBar = false;
  public searchValue = new FormControl();
  searchValue$: Subscription;
  @Input() searchLabel: string = "Buscar";
  @Input() title: string;

  @Input() set data(value: Array<T>) {

    if (value && value.length) {
      this._data = value
      this.inicializar()
    } else {
      this._data = []
      this.inicializar()
    }
  }

  inicializar() {
    this.dataSource = <any>new MatTableDataSource(this._data);
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
    this.filterValues = [];
    if (this.filters) {
      this.filters = this.filters.map(fil => ({ ...fil, options: this.getFilterObject(this._data, fil.column) }));
    }
    this.dataSource.filterPredicate = (data: any, filter: string) => {
      let searchObj = JSON.parse(filter);
      let isFilterSet = false;
      for (let index = 0; index < searchObj.length; index++) {
        const filter = searchObj[index];
        if (filter.value.toString() !== '') {
          isFilterSet = true;
        } else {
        }
      }
      if (this.searchValue.value) {
        isFilterSet = true;
      }

      let nameSearch = () => {
        let found = true;
        if (isFilterSet) {
          for (let index = 0; index < searchObj.length; index++) {
            const filter = searchObj[index];
            filter.value.trim().toLowerCase().split(' ').forEach(word => {
              if (data[filter.name].toString().toLowerCase().indexOf(word) != -1 && isFilterSet && found) {
                found = true;
              } else {
                found = false;
              }
            });
          }
          if (this.searchValue.value) {
            let searchFound = false;
            if (found) {
              Object.keys(data).forEach(col => {
                if (found && data[col] && data[col].toString().toLowerCase().indexOf(this.searchValue.value.toLowerCase().trim()) != -1) {
                  searchFound = true;
                }
              });
            }
            found = searchFound;
          }
          return found
        } else {
          return true;
        }
      }
      return nameSearch()
    }
    if (this.searchValue$) this.searchValue$.unsubscribe();
    this.searchValue.setValue("");
    this.showSearchBar = false;
    this.searchValue$ = this.searchValue.valueChanges.subscribe(value => {
      this.dataSource.filter = JSON.stringify(this.filterValues)
    })
  }

  ngAfterContentInit() {
    this.columnDefs.forEach(columnDef => {
      this.table.addColumnDef(columnDef);

    });
    this.rowDefs.forEach(rowDef => {
      this.table.addRowDef(rowDef)
    });
    this.headerRowDefs.forEach(headerRowDef => {
      this.table.addHeaderRowDef(headerRowDef);
    });



  }
  constructor() {

  }
  ngOnDestroy(): void {
    if (this.searchValue$) this.searchValue$.unsubscribe();
  }
  ngOnInit(): void {
    // this.searchValue$ = this.searchValue.valueChanges.subscribe(value => {
    //   this.dataSource.filter = JSON.stringify(this.filterValues)
    // })
  }
  handleSearchClosed() {
    this.searchValue.setValue("");
    this.showSearchBar = false;
  }
  filterChange(filter, event) {
    let value = event.value.trim().toLowerCase();
    let found = this.filterValues.find(itm => itm.name === filter.column);
    if (value) {
      if (!found) {
        found = { name: filter.column, value }
        this.filterValues.push(found)
      }
      found.value = value;
    } else {
      if (found && !value) {
        this.filterValues.splice(this.filterValues.indexOf(found), 1);
      }
    }
    this.dataSource.filter = JSON.stringify(this.filterValues)
  }
  getFilterObject(fullObj, key) {
    const uniqChk = [];
    fullObj.filter((obj) => {
      if (!uniqChk.includes(obj[key])) {
        uniqChk.push(obj[key]);
      }
      return obj;
    });
    return uniqChk;
  }
  removeFiltro(filtro, index) {
    this.filterChange({ column: filtro.name }, { value: "" });
    let idx = this.filters.findIndex(itm => itm.column === filtro.name);
    if (idx >= 0) {
      this.filters[idx].modelValue = "";
    }


  }
  resetFiltro() {
    this.filterValues = [];
    this.filters.forEach((value, key) => {
      value.modelValue = "";
    })
    this.dataSource.filter = "";
  }

}
