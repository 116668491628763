import { Component, Inject, OnInit } from '@angular/core';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UserService } from 'src/app/services/user/user.service';


@Component({
  selector: 'app-cancelar-servicio',
  templateUrl: './cancelar-servicio.component.html',
  styleUrls: ['./cancelar-servicio.component.sass']
})
export class CancelarServicioComponent implements OnInit {
  cancelaForm = new FormGroup({ motivoCancelacion: new FormControl('', Validators.required) })
  constructor(@Inject(MAT_DIALOG_DATA) private data, public dialogRef: MatDialogRef<CancelarServicioComponent>, private _ss: ServicioService,
    private _us: UserService, private _sb: MatSnackBar
  ) { }

  ngOnInit(): void {
  }


  async cancela() {

    // let ServicioService
    if (this.cancelaForm.valid && !this.cancelaForm.pristine) {
      this._ss.patchServicio(this.data.servicio.idServicio,
        {
          action: 9,
          fields: { canceladoPor: this.cancelaForm.value.motivoCancelacion }
        }).then(serv => {
          // console.log(serv);
          this._sb.open("Servicio Cancelado", "Cancelacion", { duration: 3000 })
        }).catch(err => {
          console.log(err);
          this._sb.open("Error de Cancelacion: " + err.error, "Cancelacion", { duration: 3000 })
        })
      // console.log("Servicio cancelado");
      this.dialogRef.close(true)
    }

  }

  async regresa() {
    // console.log("Servicio cancelado");
    this.dialogRef.close(true)
  }
}
