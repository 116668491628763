import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';
import { RegistroComponent } from './registro/registro.component';
import { VerificacionComponent } from './verificacion/verificacion.component';


const routes: Routes = [
    { path: '', component: MainComponent },
    { path: 'registro/:idServicio', component: RegistroComponent },
    { path: 'verificacion/:idServicio', component: VerificacionComponent },
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class SeviciosRoutingModule { }
