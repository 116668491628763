import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';
import { CatalogosService } from '../../../../../services/catalogos/catalogos.service';

@Component({
  selector: 'app-requisito',
  templateUrl: './requisito.component.html',
  styleUrls: ['./requisito.component.sass']
})
export class RequisitoComponent implements OnInit {

  idServicioProductoRequisito
  requisitoForm = new FormGroup({
    idRequisito: new FormControl(null, Validators.required),
    cumplimiento: new FormControl(null, Validators.required),
    cantidad: new FormControl(0, Validators.required),
    porcentaje: new FormControl(null, Validators.required),
    observaciones: new FormControl(null),
    otroDesc: new FormControl(null)
  })
  requisitos = []
  cumple = [{ valor: 1, texto: "SI CUMPLE" }, { valor: 0, texto: "NO CUMPLE" }]
  porcentaje = [{ valor: 1, texto: "Porcentaje(%)" }, { valor: 0, texto: "Cantidad" }]
  constructor(private _cs: CatalogosService,
    @Inject(MAT_DIALOG_DATA) public data,
    private _ss: ServicioService,
    private _snackBar: MatSnackBar,
    private _us: UtilitiesService,
    private _confirmDialog: MatDialog,
    private _snakBar: MatSnackBar,
    private _activatedRoute: ActivatedRoute,
    public matDialogRef: MatDialogRef<RequisitoComponent>) { }

  async ngOnInit() {
    this.requisitos = <any>await this._cs.getRequisito()
    console.log("Data", this.data);
    if (this.data.servicioProductoRequisito) {
      this.data.servicioProductoRequisito
      this.idServicioProductoRequisito = this.data.servicioProductoRequisito["idServicioProductoRequisito"]
      let forma = this._us.castObjetoAsProperties(Object.keys(this.requisitoForm.controls), this.data.servicioProductoRequisito)
      // console.log(servicioProductoRequisito);
      this.requisitoForm.setValue(forma)
    }
  }

  async agregaRequisito() {
    try {
      let prodReq: any
      if (this.requisitoForm.valid && !this.requisitoForm.pristine) {
        if (!this.idServicioProductoRequisito) {
          prodReq = this.requisitoForm.value
          await this._ss.postServicioProductoReq(this.data.idServicio, this.data.idServicioProducto, this.requisitoForm.value) as any
          this.idServicioProductoRequisito = prodReq.idServcioProductoRequisito
        } else {
          prodReq = this.requisitoForm.value
          await this._ss.putServicioProductoReq(this.data.idServicio, this.data.idServicioProducto, this.idServicioProductoRequisito, this.requisitoForm.value)
        }
        this._snackBar.open("Requisito actualizado", "Aviso", { duration: 3000 })

        prodReq.RequisitoFitosanitario = { nombre: this.requisitos.find(x => x.idRequisito == prodReq.idRequisito).nombre }

        this.matDialogRef.close(prodReq);
      }
    } catch (err) {
      this._snackBar.open("No se pudo actualizar el requisito", "Error", { duration: 3000 })
    }
  }


}
