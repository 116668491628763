import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';

import { merge, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { CancelarServicioComponent } from 'src/app/modules/servicios/cancelar-servicio/cancelar-servicio.component';
import { AddClienteComponent } from 'src/app/modules/shared/add-cliente/add-cliente.component';
import { ClienteService } from 'src/app/services/catalogos/cliente.service';
import { ProductoService } from 'src/app/services/catalogos/producto.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';
import { TableComponent } from 'src/app/shared/components/common/tables/table/table.component';
import { DialogService } from '../../../../services/dialog/dialog.service';
import { ModalService } from '../../../../services/dialog/modal.service';


@Component({
  selector: 'app-lista-clientes',
  templateUrl: './lista-clientes.component.html',
  styleUrls: ['./lista-clientes.component.sass']
})
export class ListaClientesComponent implements OnInit {

  isLoading = true;
  usuarios;

  displayedColumns = ["Acciones", "Nombre", "Direccion", "Contacto Nombre", "Contacto Telefono", "Contacto Email", "Activo"]; //"patente", "aduana", "pedimento", "eta"
  vals = [
    { nombreColumna: "Acciones", campo: "", tipoChip: false },
    { nombreColumna: "Nombre", campo: "nombre", tipoChip: false },
    { nombreColumna: "Direccion", campo: "direccion", tipoChip: false },
    { nombreColumna: "Contacto Nombre", campo: "contactoNombre", tipoChip: false },
    { nombreColumna: "Contacto Telefono", campo: "contactoTelefono", tipoChip: false },
    { nombreColumna: "Contacto Email", campo: "contactoEmail", tipoChip: false },
    { nombreColumna: "Activo", campo: "activo", tipoChip: true }
  ];

  status = [{ id: '0', nombre: 'Inactivo', color: 'gray' }, { id: '1', nombre: 'Activo', color: 'green' }]

  getStatus(tipo, id) {
    if (tipo == "activo") {
      return this.status.find(st => st.id == id);
    }
  }
  obtenerValor(row) {
    if (typeof row === 'object' && row !== null) {
      return row.descripcion;//[val.campo]
    }
    else {
      return row;
    }
  }
  getObj(value) {
    return this.vals.find(val => val.nombreColumna === value);
  }
  AbrirMenu() {
    this.trigger.openMenu();
  }

  resultsLength = 0; 0
  pageSize = 10;
  // searchBy;// { value: "referencia", text: 'Referencia' }
  attributes = 'attributes[0]=idCliente&attributes[1]=nombre&attributes[2]=direccion&attributes[3]=contactoNombre&attributes[4]=contactoEmail&attributes[5]=contactoTelefono&attributes[6]=activo';
  searchBy = { value: 'nombre', text: 'Nombre' }//'filters[activo][eq]=1';
  filters = 'filters[activo][in]=1'
  busquedasDispoible = [];

  searchOptions: any[] = [{ value: "fase", text: 'Fase' },
  { value: "movilidad", text: 'Movilidad' },
  { value: "internacional", text: 'Internacional' },
  { value: "otroServicio", text: 'Otro Servicio' },
  ];
  panelOpenState = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() resultadoReporte;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(TableComponent, { static: false }) public table: TableComponent<any>;
  constructor(private _cs: ClienteService, private _router: Router, private _dialog: MatDialog, private _sb: MatSnackBar, private _ds: DialogService, private _ms: ModalService) { }

  ngOnInit(): void {
    // console.log(this.resultadoReporte);
    this.displayedColumns = [...this.displayedColumns];
    // this.servicios = this.resultadoReporte;

    console.log("Esta es la lista de servicios");
  }

  ngAfterViewInit() {
    this.actualizarReporte()
  }
  handleSearchChange(event) {
    console.log("Busqueda del reporte");
  }
  verMas(element) {
    console.log("Ver mas");
  }
  actualizarReporte() {
    merge(this.table.sort.sortChange, this.table ? this.table.paginator.page : [], this.table.searchValue.valueChanges.pipe(debounceTime(500)))
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          // let chipsFilters = this.agregarFiltros();
          return this._cs.getClientes(`${this.attributes}&${this.filters}${this.table.searchValue.value ? `&filters[${this.searchBy.value}][like]=%25${this.table.searchValue.value.replace("&", "%26")}%25` : ''}&size=${this.table.paginator.pageSize}&page=${this.table.paginator.pageIndex}`);
        }),
        map((data: any) => {
          this.isLoading = false;
          this.resultsLength = data.count;
          return data.rows
          //return [];
        }),
        catchError(() => {
          this.isLoading = false;

          return of([]);
        })
      )
      .subscribe((data: any) => {
        if (data) {
          // this._ss.setPreference("pageSize", this.table.paginator.pageSize.toString());
          this.usuarios = data;
          // this.getFiltros();

        } else {
          this.usuarios = [];
        }
      });
  }

  desactiva(cliente) {
    this.showConfirmaDesactivar({ title: "Desactivar", message: `Confirma desactivar al cliente ${cliente.nombre}`, cancelButton: "Cancelar", okButton: "Aceptar" }, cliente)
    // console.log(`verificacion/${id}`);
    // this._router.navigate([`servicios/verificacion/${id}`])
  }
  async activa(cliente) {
    try {
      let resp = await this._ds.askFor("Activar Cliente?", "Desea activar el cliente?");
      if (resp) {
        await this._cs.patchCliente(cliente.idCliente, "activate", {})
        this.actualizarReporte()
      }
    } catch (error) {

    }
  }

  async edita(cliente) {
    try {
      const resp = await this._dialog.open(AddClienteComponent, this._ms.modalConfig({ cliente })).afterClosed().toPromise();
      if (resp) {
        this.actualizarReporte()
      }
    } catch (error) {

    }

  }

  reset(id) {

  }

  asigna(servicio) {
  }

  imprimir(idServicio) {
  }

  agregaUsuario() {
    // this._router.navigate([`servicios/registro/new`])

  }
  async showAgregaCliente(producto) {
    const dialogRef = this._dialog.open(AddClienteComponent, {
      // panelClass: "sixtyprc-dialog",
      disableClose: false,
      maxHeight: '60%',
      data: producto
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          this.actualizarReporte()
          // this._servicioService.patchServicio(this.idServicio, { action: 3 })
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }

  async showConfirmaDesactivar(parametros, cliente) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title: parametros.title, message: parametros.message, cancelButton: parametros.cancelButton, okButton: parametros.okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          await this._cs.patchCliente(cliente.idCliente, "deactivate", {})
          this.actualizarReporte()
        }
      } catch (err) {
        console.log(err);
        this._sb.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }
}
