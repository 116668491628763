import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ProductoService } from 'src/app/services/catalogos/producto.service';
import { ProductoComponent } from '../../servicios/registro/producto/producto.component';


@Component({
  selector: 'app-add-producto',
  templateUrl: './add-producto.component.html',
  styleUrls: ['./add-producto.component.sass']
})
export class AddProductoComponent implements OnInit {

  productosForm = new FormGroup({
    descripcion: new FormControl(null, Validators.required),
    usos: new FormControl(null),
    presentacion: new FormControl(null),
  })

  Usos = [];
  Presentaciones = [];

  eliminaPresentacion(presentacion) {
    // console.log(presentacion);
    this.Presentaciones.splice(this.Presentaciones.indexOf(presentacion), 1)
  }

  eliminaUso(uso) {
    // console.log(presentacion);
    this.Usos.splice(this.Usos.indexOf(uso), 1)
  }


  async agregarUso() {
    console.log(this.productosForm.get('usos').value);
    if (this.productosForm.get('usos').value) {
      this.Usos.push(this.productosForm.get('usos').value);

      this.productosForm.get('usos').reset();
      console.log(this.Usos);
    }
  };
  agregarPresentacion = () => {
    console.log(this.productosForm.get('presentacion').value);
    if (this.productosForm.get('presentacion').value) {
      this.Presentaciones.push(this.productosForm.get('presentacion').value);

      this.productosForm.get('presentacion').reset();
      console.log(this.Presentaciones);
    }
  };

  constructor(@Inject(MAT_DIALOG_DATA) private data,
    private _wp: ProductoService,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<ProductoComponent>) {
    if (this.data) {
      this.productosForm.patchValue({ idProducto: this.data.idProducto, descripcion: this.data.descripcion })
      this.Usos = JSON.parse(this.data.usos)
      console.log(this.data.presentacion);
      this.Presentaciones = JSON.parse(this.data.presentacion)
    }
  }

  ngOnInit(): void {
  }

  async guardaProducto() {
    if (this.Usos.length !== 0 && this.Presentaciones.length !== 0) {
      let descripcion = this.productosForm.get('descripcion').value;

      //Arreglo de usos
      let usos = JSON.stringify(this.Usos)
      //Arreglo de presentaciones
      let presentaciones = JSON.stringify(this.Presentaciones)
      this.productosForm.get('usos').setValue(usos);
      this.productosForm.get('presentacion').setValue(presentaciones);
      try {
        let respuestaProductoWs
        if (!this.data) {
          respuestaProductoWs = await this._wp.postProducto({ descripcion, usos, presentacion: presentaciones }) as any
        } else {
          respuestaProductoWs = await this._wp.putProducto(this.data.idProducto, { descripcion, usos, presentacion: presentaciones }) as any
        }


        if (respuestaProductoWs) {
          this._snackBar.open("Producto agregado correctamente", "Success", { duration: 3000 })
          this.matDialogRef.close({ descripcion });
        }

      } catch (err) {
        throw err
      }

    } else {
      this._snackBar.open("Debe agregar usos y presentaciones del producto", "Error", { duration: 3000 })
    }

  }

}
