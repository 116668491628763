import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-table-filter',
  templateUrl: './table-filter.component.html',
  styleUrls: ['./table-filter.component.sass']
})
export class TableFilterComponent implements OnInit {
  @Input() showReset = false;
  @Output() onReset = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }

}
