import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { merge, of } from 'rxjs';
import { MatSort } from '@angular/material/sort';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { TableComponent } from 'src/app/shared/components/common/tables/table/table.component';
import { MatMenuTrigger } from '@angular/material/menu';
import { Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { AsignarServicioComponent } from '../asignar-servicio/asignar-servicio.component';
import { CancelarServicioComponent } from '../cancelar-servicio/cancelar-servicio.component';
import * as FileSaver from 'file-saver';
import { MatRadioChange } from '@angular/material/radio';

@Component({
  selector: 'app-servicios-reporte',
  templateUrl: './servicios-reporte.component.html',
  styleUrls: ['./servicios-reporte.component.sass']
})
export class ServiciosReporteComponent implements OnInit {

  // Declaraciones
  isLoading = true;
  servicios;

  displayedColumns = ["Acciones", "Status", "Tipo Servicio", "Cliente", "Destinatario", "Remitente", "Lugar Solicitud", "Folio", "Asignado a", "Programado", "Creado por"]; //"patente", "aduana", "pedimento", "eta"
  vals = [
    { nombreColumna: "Acciones", campo: "", tipoChip: false },
    { nombreColumna: "Servicio", campo: "idServicio", tipoChip: false },
    { nombreColumna: "Status", campo: "status", tipoChip: true },
    { nombreColumna: "Tipo Servicio", campo: "verificacion", tipoChip: true },
    { nombreColumna: "Cliente", campo: "Cliente", tipoChip: false },
    { nombreColumna: "Destinatario", campo: "Destinatario", tipoChip: false },
    { nombreColumna: "Remitente", campo: "Remitente", tipoChip: false },
    { nombreColumna: "Lugar Solicitud", campo: "LugarSolicitud", tipoChip: false },
    { nombreColumna: "Folio", campo: "folio", tipoChip: false },
    { nombreColumna: "Asignado a", campo: "AsignadoA", tipoChip: false },
    { nombreColumna: "Programado", campo: "fechaProgramacion", tipoChip: false },
    { nombreColumna: "Creado por", campo: "CreadoPor", tipoChip: false }
  ];
  tipoServicio = [
    { id: 1, nombre: 'Nac', color: 'green' },
    { id: 2, nombre: 'Int', color: '#0000FF' },
    { id: 9, nombre: 'Otro', color: '#FF9900' }
  ];
  tipoStatus = [
    { id: '0', nombre: 'Generado', color: 'blue' },
    { id: '1', nombre: 'Asignado', color: '#FF9900' },
    { id: '2', nombre: 'Verificación', color: '#F597FF' },
    { id: '3', nombre: 'Finalizado', color: '#38761D' },
    { id: '9', nombre: 'Cancelado', color: '#grey' }
  ];

  getServicioOStatus(tipo, id) {
    if (tipo == "status") {
      return this.tipoStatus.find(st => st.id == id);
    } else {
      return this.tipoServicio.find(serv => serv.id == id);
    }
  }

  obtenerValor(row) {
    if (typeof row === 'object' && row !== null) {
      return row.nombre;//[val.campo]
    }
    else {
      return row;
    }
  }
  getObj(value) {
    return this.vals.find(val => val.nombreColumna === value);
  }
  AbrirMenu() {
    this.trigger.openMenu();
  }

  resultsLength = 0;
  pageSize = 10;
  pageIndex = 0
  attributes = 'attributes[0]=idServicio&attributes[1]=status&attributes[2]=fase&attributes[3]=movilidad&attributes[4]=internacional&attributes[5]=otroServicio&attributes[6]=verificacion&attributes[7]=fechaProgramacion&attributes[8]=folio';
  filters = 'filters[activo][in]=1' // 'filters[status][in]=1,0&filters[fechaSolicitud][between]=2021-08-01,2021-09-12';
  searchBy = { value: 'folio', text: 'Folio' };// { value: "referencia", text: 'Referencia' }
  busquedasDispoible: any[] = [
    { value: '$AsignadoA.nombre$', text: 'Asignado a' },
    { value: 'folio', text: 'Folio' },
    { value: '$Cliente.nombre$', text: 'Cliente' },
    { value: '$Destinatario.nombre$', text: 'Destinatario' },
    { value: '$Remitente.nombre$', text: 'Remitente' },
  ];
  usrConfig = JSON.parse(localStorage.getItem("BIT_usrConfig")) as any;

  searchOptions: any[] = [{ value: "fase", text: 'Fase' },
  { value: "movilidad", text: 'Movilidad' },
  { value: "internacional", text: 'Internacional' },
  { value: "otroServicio", text: 'Otro Servicio' },
  ];
  panelOpenState = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() resultadoReporte;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(TableComponent, { static: false }) public table: TableComponent<any>;
  constructor(private _ss: ServicioService, private _router: Router, private _dialog: MatDialog) { }

  ngOnInit(): void {
    // console.log(this.resultadoReporte);
    this.displayedColumns = [...this.displayedColumns];
    this.servicios = this.resultadoReporte.rows;

    console.log(this.usrConfig);
    // if (usrConfig.idRol == 1 || usrConfig.idRol == 2) {
    console.log("Esta es la lista de servicios", this.servicios);

  }

  ngAfterViewInit() {
    this.actualizarReporte()
  }
  handleSearchChange(opcion: MatRadioChange) {
    this.searchBy = opcion.value;
    if (this.table.searchValue.value) this.table.searchValue.setValue(this.table.searchValue.value)
  }
  verMas(element) {
    console.log("Ver mas");
  }
  actualizarReporte() {
    // this.table.paginator.pageSize = this.pageSize
    // this.table.paginator.pageIndex = this.pageIndex
    merge(this.table.sort.sortChange, this.table.paginator.page, this.table.searchValue.valueChanges.pipe(debounceTime(500)))
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          // let chipsFilters = this.agregarFiltros();
          // console.log("Paginador", `${this.attributes}&${this.filters}${this.table.searchValue.value ? `&filters[${this.searchBy.value}][like]=%25${this.table.searchValue.value.replace("&", "%26")}%25` : ''}`);
          return this._ss.getServicios(`${this.attributes}&${this.filters}${this.table.searchValue.value ? `&filters[${this.searchBy.value}][like]=%25${this.table.searchValue.value.replace("&", "%26")}%25` : ''}&size=${this.table.paginator.pageSize}&page=${this.table.paginator.pageIndex}`);
        }),
        map((data: any) => {
          this.isLoading = false;
          this.resultsLength = data.count;
          return data.rows
          //return [];
        }),
        catchError(() => {
          this.isLoading = false;

          return of([]);
        })
      )
      .subscribe((data: any) => {
        if (data) {
          // this._ss.setPreference("pageSize", this.table.paginator.pageSize.toString());
          this.servicios = data;
          // this.getFiltros();

        } else {
          this.servicios = [];
        }
      });
  }

  verifica(id) {
    // console.log(id)
    // console.log(`verificacion/${id}`);
    this._router.navigate([`servicios/verificacion/${id}`])
  }

  edita(id) {
    // console.log(id)
    // console.log(`verificacion/${id}`);
    this._router.navigate([`servicios/registro/${id}`])
  }

  cancela(servicio) {
    const dialogRef = this._dialog.open(CancelarServicioComponent, {
      width: '1100px',
      data: { servicio: servicio }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("index", index, result);
      if (result) {

      }
      this.actualizarReporte()
      // console.log('The dialog was closed');
      // this.animal = result;
    });
  }

  asigna(servicio) {
    const dialogRef = this._dialog.open(AsignarServicioComponent, {
      // width: '1100px',
      data: { servicio: servicio }
    });

    dialogRef.afterClosed().subscribe(result => {
      // console.log("index", index, result);
      if (result) {

      }
      // console.log('The dialog was closed');
      this.actualizarReporte()
      // this.animal = result;
    });
  }

  imprimir(idServicio) {
    this._ss.getServicioDocumento(idServicio)
      // await this._is
      //   .postDescargaComprobanteZip(this._loginService.getToken(), this.comprobante.get('idComprobante').value)
      .then(data => {
        console.log(data);
        // var blob = new Blob([data], { type: 'text/xml' })
        var blob = new Blob([data], { type: 'application/pdf' });
        console.log(blob);

        // let url = window.URL.createObjectURL(blob);
        // console.log(url);

        // window.open(url);
        FileSaver.saveAs(blob, `servicio - ${idServicio}`);
      });
  }

  agregaServicio() {
    this._router.navigate([`servicios/registro/new`])
  }

  async showConfirmaCancelacion(title: string, message: string, cancelButton: string, okButton: string) {
    const dialogRef = this._dialog.open(CancelarServicioComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title, message, cancelButton, okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          // this._servicioService.patchServicio(this.idServicio, { action: 3 })
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }
}
