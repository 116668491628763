import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { UsuarioService } from 'src/app/services/catalogos/usuario.service';
import { AddUsuarioRegulacionesComponent } from '../add-usuario-regulaciones/add-usuario-regulaciones.component';
import { DialogService } from '../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-lista-usuario-regulaciones',
  templateUrl: './lista-usuario-regulaciones.component.html',
  styleUrls: ['./lista-usuario-regulaciones.component.sass']
})
export class ListaUsuarioRegulacionesComponent implements OnInit {

  constructor(private _dialog: MatDialog,
    private _ds: DialogService,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<any>,
    private _us: UsuarioService, @Optional() @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit(): void {


  }

  async agregaRegulacion() {
    // console.log(this.usuarios);
    const dialogRef = this._dialog.open(AddUsuarioRegulacionesComponent, {
      disableClose: false,
      // maxHeight: '60%',
      width: '50%',
      data: this.data
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          this.data.id.UsuarioRegulacions.push(result)
          console.log(this.data.id.UsuarioRegulacions)
        }
      } catch (err) {
      }
    })
  }
  async handleEditarRegulacion(regulacion) {
    try {
      const dialogRef = this._dialog.open(AddUsuarioRegulacionesComponent, { disableClose: false, width: '50%', data: { ...this.data, regulacion } });
      let resp = await dialogRef.afterClosed().toPromise();
      if (resp) {
        console.log(resp);
        if (regulacion) {

        } else {
          this.data.id.UsuarioRegulacions.push(resp)
        }

      }
    } catch (error) {

    }
  }

  async handleEliminarRegulacion(regulacion, index) {
    try {
      let resp = await this._ds.askFor("Eliminar Regulación", "Esta seguro de eliminar la regulción");
      if (resp) {
        let { idUsuario, idUsuarioRegulacion } = regulacion;
        await this._us.deleteUsuarioRegulacion(idUsuarioRegulacion, idUsuario);
        this.data.id.UsuarioRegulacions.splice(index, 1);
        this._ds.showSnack("Regulación eliminada correctamente");
      }
    } catch (error) {

    }

  }
}
