export class ConfirmDialogData {
  title: string;
  message: string;
  okButton: string;
  cancelButton: string;
  constructor(
    title: string,
    message: string,
    okButton: string,
    cancelButton: string
  ) {
    this.title = title;
    this.message = message;
    this.okButton = okButton;
    this.cancelButton = cancelButton;
  }
}
