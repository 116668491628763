import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class NavService {


  private _sideNavOpened = new Subject<boolean>();
  sideNavOpened = this._sideNavOpened.asObservable();
  public isSideNavOpened = true;

  constructor(private _ss: StorageService) {
    let isMenuOpen = this._ss.getPreference("isMenuOpen");
    this.isSideNavOpened = isMenuOpen === "true" ? true : false;
    this._sideNavOpened.next(this.isSideNavOpened);
  }
  menuToggle() {
    this.isSideNavOpened = !this.isSideNavOpened;
    this._ss.setPreference("isMenuOpen", String(this.isSideNavOpened));
    this._sideNavOpened.next(this.isSideNavOpened);
  }
  setNavStatus(opened: any) {
    this.isSideNavOpened = opened;
    this._ss.setPreference("isMenuOpen", String(this.isSideNavOpened));
    this._sideNavOpened.next(this.isSideNavOpened);
  }
}
