import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';


// import { ReportesRoutingModule } from './reportes-routing.module';
import { NavbarComponent } from './layout/navbar/navbar.component';
import { SidenavComponent } from './layout/sidenav/sidenav.component';
// import { DashboardComponent } from './dashboard.component';
import { MainComponent } from './main/main.component';
import { SolicitudComponent } from './solicitud/solicitud.component';
import { ReportesRoutingModule } from './reportes-routing.module';
import { ReportesComponent } from './reportes.component';



@NgModule({
  declarations: [
    SolicitudComponent,
    NavbarComponent,
    SidenavComponent,
    MainComponent,
    ReportesComponent,

  ],
  imports: [
    SharedModule,
    CommonModule,
    ReportesRoutingModule
  ]
})
export class ReportesModule { }
