import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// Angular Modules
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";

// Modules
import { AngularMaterialModule } from "./angular-material/angular-material.module";
//import { ChartsModule } from 'ng2-charts';

// Directives
import { AutofocusDirective } from './directives/autofocus.directive';

// Components
import { FullscreenDialogComponent } from './components/common/modals/fullscreen-dialog/fullscreen-dialog.component';
import { ConfirmDialogComponent } from './components/common/modals/confirm-dialog/confirm-dialog.component';
import { ButtonLoadingComponent } from './components/common/buttons/button-loading/button-loading.component';
import { TableComponent } from './components/common/tables/table/table.component';
import { CustomModalComponent } from './components/common/modals/custom-modal/custom-modal.component';
import { CustomLoaderComponent } from './components/common/loaders/custom-loader/custom-loader.component';
import { TableFilterComponent } from './components/common/tables/table-filter/table-filter.component';
import { TableAdvancedSearchComponent } from './components/common/tables/table-advanced-search/table-advanced-search.component';
import { ToolboxComponent } from './components/common/navbar/toolbox/toolbox.component';
import { SyncTableComponent } from './components/common/tables/sync-table/sync-table.component';
import { SimpleOnboardComponent } from './components/common/onboard/simple-onboard/simple-onboard.component';

@NgModule({
  declarations: [
    // Modals
    FullscreenDialogComponent,
    ConfirmDialogComponent,

    // Directivas
    AutofocusDirective,
    // Componentes de App
    ButtonLoadingComponent,
    TableComponent,
    CustomModalComponent,
    CustomLoaderComponent,
    TableFilterComponent,
    TableAdvancedSearchComponent,
    ToolboxComponent,
    SyncTableComponent,
    SimpleOnboardComponent,
    //PatenteOficinaAutocompleteComponent,
  ],
  imports: [
    CommonModule,
    // Angular Modules
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    // Angular Material
    AngularMaterialModule,
  ],
  exports: [
    // Angular Modules
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    // Angular Material
    AngularMaterialModule,
    // Modals
    FullscreenDialogComponent,
    ConfirmDialogComponent,
    // Directivas
    AutofocusDirective,
    // Componentes de App
    ButtonLoadingComponent,
    TableComponent,
    SyncTableComponent,
    CustomModalComponent,
    CustomLoaderComponent,
    ToolboxComponent,
    SimpleOnboardComponent,
    //PatenteOficinaAutocompleteComponent,
    TableFilterComponent,
    TableAdvancedSearchComponent,

  ],
  entryComponents: [
    // Modals
    FullscreenDialogComponent,
    ConfirmDialogComponent
  ]
})
export class SharedModule { }
