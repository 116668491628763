<div class="center-align">
    <div *ngIf="show" class="animated fadeIn">
        <mat-progress-spinner
            *ngIf="type == 0"
            class="example-margin"
            [color]="color"
            [mode]="mode"
            [value]="value"
            [diameter]="diameter">
        </mat-progress-spinner>
        <mat-progress-bar
            *ngIf="type == 1"
            [mode]="mode"
            [color]="color"></mat-progress-bar>
        <h2 class="example-h2">{{ message }}</h2>
    </div>
    <h3 class="animated fadeIn" *ngIf="showNoDataMessage">{{ noDataMessage }}</h3>
</div>
