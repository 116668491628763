<div style="">
    <div class="row">
        <div class="col s12 l6">
            <form [formGroup]="ubicacionesForm" (ngSubmit)="guardaUbicacion()">
                <div class="row">
                    <div class="title center-align col l12 m12 s12">
                        <span>Agrega Ubicación</span>
                    </div>
                </div>
                <div class="row">
                    <div class="col l12 m12 s12">
                        <mat-form-field appearance="fill">
                            <mat-label>Nombre</mat-label>
                            <input matInput formControlName="nombre">
                        </mat-form-field>
                    </div>
                    <div class="col l12 m12 s12">
                        <mat-form-field appearance="fill">
                            <mat-label>Dirección</mat-label>
                            <input autocomplete="on" matInput (keydown.enter)="$event.preventDefault()"
                                placeholder="Search Nearest Location" autocorrect="off" autocapitalize="off"
                                spellcheck="off" #search formControlName="direccion">
                        </mat-form-field>
                    </div>
                    <div class="col l6 m12 s12">
                        <mat-form-field appearance="fill">
                            <mat-label>Latitud</mat-label>
                            <input matInput formControlName="latitud" readonly="true">
                        </mat-form-field>
                    </div>
                    <div class="col l6 m12 s12">
                        <mat-form-field appearance="fill">
                            <mat-label>Longitud</mat-label>
                            <input matInput formControlName="longitud" readonly="true">
                        </mat-form-field>
                    </div>
    
                    <div class="col s12 l12 m12">
                        <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                            <mat-icon>save</mat-icon>
                            Agregar
                        </button>
                    </div>
    
                </div>
            </form>
        </div>
        <div class="col s12 l5">   
            <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" style="width: 400px;height: 400px;">
                <agm-marker [latitude]="latitude" [longitude]="longitude" [markerDraggable]="true"></agm-marker>
            </agm-map>
        </div>
    </div>
    
</div>