<h1 mat-dialog-title>
    {{ confirmDialogData.title }}
</h1>
<div mat-dialog-content>
    <p>{{ confirmDialogData.message }}</p>
</div>
<div mat-dialog-actions class="right-align">
    <button mat-button [mat-dialog-close]="false">
        {{ confirmDialogData.cancelButton }}
    </button>
    <button mat-button color="accent" [mat-dialog-close]="true" cdkFocusInitial>
        {{ confirmDialogData.okButton }}
    </button>
</div>
