<!-- <div class="app-container"> -->
<form [formGroup]="cancelaForm" (ngSubmit)="cancela()">
    <mat-form-field class="example-full-width" appearance="fill">
        <mat-label>Motivo de Cancelacion</mat-label>
        <textarea matInput formControlName="motivoCancelacion" placeholder="Se cancela por..."></textarea>
    </mat-form-field>

    <button mat-button mat-dialog-close color="primary">Regresar</button>
    <!-- <button mat-button color="accent">Accent</button> -->
    <button type="submit" mat-button color="primary">Cancelar servicio</button>
</form>
<!-- </div> -->