<form [formGroup]="productosForm" (ngSubmit)="guardaProducto()">
    <div class="row">
        <div class="title center-align col l12 m12 s12">
            <span>Agrega Productos</span>
        </div>
    </div>
    <div class="row">
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="descripcion">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Agregar uso</mat-label>
                <input matInput formControlName="usos">
                <mat-icon matSuffix style="cursor:pointer" (click)="agregarUso()">add</mat-icon>
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <span *ngFor="let uso of Usos" class="col chip" style="background-color:#D3D3D3">{{ uso }}
                <mat-icon style="font-size: medium; padding-top: 2px; color: gray" class="chip"
                    (click)="eliminaUso(uso)">clear</mat-icon>
            </span>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Agregar presentación</mat-label>
                <input matInput formControlName="presentacion">
                <mat-icon matSuffix style="cursor:pointer" (click)="agregarPresentacion()">add</mat-icon>
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <span *ngFor="let presentacion of Presentaciones" class="col chip col" style="background-color:#D3D3D3">{{
                presentacion }}
                <!-- <mat-icon (click)="eliminaPresentacion(presentacion)"> -->
                <mat-icon style="font-size: medium; padding-top: 2px; color: gray" class="chip"
                    (click)="eliminaPresentacion(presentacion)">clear</mat-icon>
                <!-- </button> -->
            </span>
        </div>

        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Agregar
            </button>
        </div>

    </div>
</form>