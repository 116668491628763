<h3 mat-dialog-title>{{title}}</h3>
<div mat-dialog-content>
    <ng-content>

    </ng-content>
</div>
<div mat-dialog-actions>
    <div class="actions">
        <ng-content select=".modal-actions">

        </ng-content>
    </div>

</div>
