import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { NavService } from '../../../../../services/nav/nav.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-toolbox',
  templateUrl: './toolbox.component.html',
  styleUrls: ['./toolbox.component.sass']
})
export class ToolboxComponent implements OnInit, OnDestroy {
  @Input() title: string;
  toggleSubs: Subscription;
  opened;
  constructor(private _ns: NavService) {
    this.opened = this._ns.isSideNavOpened;
  }
  ngOnDestroy(): void {
    if (this.toggleSubs) this.toggleSubs.unsubscribe();
  }

  ngOnInit(): void {
    this.toggleSubs = this._ns.sideNavOpened.subscribe(toggle => {
      this.opened = toggle
    });

  }
}
