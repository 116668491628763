import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user/user.service';
import { NavService } from '../../../../services/nav/nav.service';
import { StorageService } from '../../../../services/storage/storage.service';
import { IMG } from 'src/app/config/url.services';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass']
})
export class NavbarComponent implements OnInit {

  img = IMG
  isMenuOpened = true;
  constructor(public _us: UserService, private _router: Router, private _ns: NavService, private _ss: StorageService) {
    let isMenuOpen = this._ss.getPreference("isMenuOpen");
    isMenuOpen = isMenuOpen === "true" ? true : false;
    this.isMenuOpened = isMenuOpen;
  }

  ngOnInit() {

  }
  async logout() {
    try {
      await this._us.logout()
      this._router.navigate(["login"]);
    } catch (error) {
      console.log(error);
      this._router.navigate(["login"]);
    }
  }

  toggleAppMenu() {
    this._ns.menuToggle();
  }

  catalogos() {
    this._router.navigate(["catalogos"]);
  }

  servicios() {
    this._router.navigate(["servicios"]);
  }
}
