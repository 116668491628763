import { BreakpointObserver, Breakpoints } from "@angular/cdk/layout";
import { Component, Input, OnInit, Output, ViewChild, EventEmitter } from '@angular/core';
import { StorageService } from '../../../../services/storage/storage.service';
import { map, filter } from 'rxjs/operators';
import { MatDrawer, MatDrawerMode, MatSidenav } from '@angular/material/sidenav';
import { NavService } from '../../../../services/nav/nav.service';
import { Subscription } from 'rxjs';
import { UserService } from '../../../../services/user/user.service';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.sass']
})
export class SidenavComponent implements OnInit {
  @Output() onNuevaReferencia = new EventEmitter();
  @ViewChild("appSideNav", { static: true }) appSideNav: MatSidenav;
  opened: boolean;
  sideNavStatus$: Subscription;
  sideNavOpenChange$: Subscription;
  mode: MatDrawerMode = "side"
  topGap = 64;
  fixedInViewport = true;

  menu = [
    // { name: "Clientes", icon: "account_box", children: null, redirectTo: "lista-clientes" },
    // { name: "Productos", icon: "shopping_cart", children: null, redirectTo: "lista-productos" },
    // { name: "Ubicaciones", icon: "location_on", children: null, redirectTo: "lista-ubicaciones" },
    // { name: "Usuarios", icon: "people", children: null, redirectTo: "lista-usuarios" },
    // { name: "Folios", icon: "format_list_numbered", children: null, redirectTo: "lista-folios" }
  ];
  bloqueos = [];
  constructor(
    private _ns: NavService,
    private bkpoint: BreakpointObserver,
    private _us: UserService
    //private _navs: NavService
  ) {

  }

  ngOnInit() {

    let usrConfig = JSON.parse(localStorage.getItem("BIT_usrConfig")) as any;

    if (usrConfig.idRol == 1 || usrConfig.idRol == 2) {
      this.menu = [
        { name: "Clientes", icon: "account_box", children: null, redirectTo: "lista-clientes" },
        { name: "Productos", icon: "shopping_cart", children: null, redirectTo: "lista-productos" },
        { name: "Ubicaciones", icon: "location_on", children: null, redirectTo: "lista-ubicaciones" },
        { name: "Usuarios", icon: "people", children: null, redirectTo: "lista-usuarios" },
        { name: "Folios", icon: "format_list_numbered", children: null, redirectTo: "lista-folios" }
      ];
    } else if (usrConfig.idRol = !1 && usrConfig.idRol != 6) {
      this.menu = [
        { name: "Clientes", icon: "account_box", children: null, redirectTo: "lista-clientes" },
        { name: "Productos", icon: "shopping_cart", children: null, redirectTo: "lista-productos" },
        { name: "Ubicaciones", icon: "location_on", children: null, redirectTo: "lista-ubicaciones" },
        // { name: "Usuarios", icon: "people", children: null, redirectTo: "lista-usuarios" },
        { name: "Folios", icon: "format_list_numbered", children: null, redirectTo: "lista-folios" }
      ];
    } else {
      this.menu = [
        // { name: "Clientes", icon: "account_box", children: null, redirectTo: "lista-clientes" },
        // { name: "Productos", icon: "shopping_cart", children: null, redirectTo: "lista-productos" },
        // { name: "Ubicaciones", icon: "location_on", children: null, redirectTo: "lista-ubicaciones" },
        // { name: "Usuarios", icon: "people", children: null, redirectTo: "lista-usuarios" },
        // { name: "Folios", icon: "format_list_numbered", children: null, redirectTo: "lista-folios" }
      ];
    }
    const breakpoints = Object.keys(Breakpoints).map((key) => Breakpoints[key]);
    this.bkpoint.observe(breakpoints).pipe(map((bst) => bst.matches))
      .subscribe((matched) => {
        this.determineSidenavMode();
        this.determineLayoutGap();
      });

    this.sideNavStatus$ = this._ns.sideNavOpened.subscribe((obs) => {
      this.opened = obs;
    });
    if (this._ns.isSideNavOpened) {
      this.opened = true;
    } else {
      this.opened = false;
    }
    this.sideNavOpenChange$ = this.appSideNav.openedChange.subscribe(status => {
      this._ns.setNavStatus(status);
    })
    //this.getMenu()
  }
  // async getMenu() {
  //   try {
  //     const menu = await this._us.getMenu();

  //     this.menu = menu.filter(item => item.visible);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }
  ngOnDestroy(): void {

    if (this.sideNavStatus$) this.sideNavStatus$.unsubscribe();
    if (this.sideNavOpenChange$) this.sideNavOpenChange$.unsubscribe();
  }
  private determineSidenavMode(): void {
    if (this.isExtraSmallDevice()) {
      this.fixedInViewport = false;
      this.mode = "over";
      return;
    }
    this.fixedInViewport = true;
    this.mode = "side";
    this.topGap = 64;
  }

  private determineLayoutGap(): void {
    if (this.isExtraSmallDevice()) {
      this.topGap = 0;
      return;
    }
    this.topGap = 64;
  }

  public isExtraSmallDevice(): boolean {
    return this.bkpoint.isMatched(Breakpoints.XSmall);
  }
  public isSmallDevice(): boolean {
    return this.bkpoint.isMatched(Breakpoints.Small);
  }

  handleNuevaReferencia() {
    this.onNuevaReferencia.emit("");

  }



}
