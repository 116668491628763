import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';
import { UsuarioService } from 'src/app/services/catalogos/usuario.service';

@Component({
  selector: 'app-add-usuario-regulaciones',
  templateUrl: './add-usuario-regulaciones.component.html',
  styleUrls: ['./add-usuario-regulaciones.component.sass']
})
export class AddUsuarioRegulacionesComponent implements OnInit {
  regulaciones = []
  idUsuarioRegulacion;
  regulacionesAsignadas = [];
  //usuario;
  constructor(private _cs: CatalogosService, private _snackBar: MatSnackBar, public matDialogRef: MatDialogRef<any>, private _us: UsuarioService, @Optional() @Inject(MAT_DIALOG_DATA) public data) {

  }
  usuarioRegulacionForm = new FormGroup({
    idUsuarioRegulacion: new FormControl(null),
    idUsuario: new FormControl(null, Validators.required),
    idRegulacion: new FormControl(null, Validators.required),
    fechaVencimiento: new FormControl(null, Validators.required),
  })
  async ngOnInit() {
    this.regulacionesAsignadas = this.data.id.UsuarioRegulacions.map(itm => itm.idRegulacion);
    console.log(this.regulacionesAsignadas);
    this.regulaciones = <any>await this._cs.getRegulaciones()
    this.usuarioRegulacionForm.controls["idUsuario"].setValue(this.data.id.idUsuario)
    if (this.data.regulacion) {
      this.usuarioRegulacionForm.patchValue(this.data.regulacion)
    }

  }

  async guardaRegulacion() {

    if (this.usuarioRegulacionForm.valid && !this.usuarioRegulacionForm.pristine) {
      try {
        let { idUsuarioRegulacion } = this.usuarioRegulacionForm.value;
        let UsuarioRegulacion;
        if (!idUsuarioRegulacion) {
          UsuarioRegulacion = await this.postUsuarioRegulacion();

        } else {
          let { idUsuarioRegulacion } = this.usuarioRegulacionForm.value;
          await this._us.putUsuarioRegulacion(idUsuarioRegulacion, this.usuarioRegulacionForm.value);
          UsuarioRegulacion = this.data.regulacion;
          let { fechaVencimiento, idRegulacion } = this.usuarioRegulacionForm.value
          UsuarioRegulacion.fechaVencimiento = fechaVencimiento;
          UsuarioRegulacion.idRegulacion = idRegulacion;
          UsuarioRegulacion.Regulacion = this.regulaciones.find(x => x.idRegulacion == this.usuarioRegulacionForm.value.idRegulacion);
        }
        this._snackBar.open("Informacion actualizada", "Aviso", { duration: 3000 })
        this.matDialogRef.close({ ...UsuarioRegulacion, Regulacion: this.regulaciones.find(x => x.idRegulacion == this.usuarioRegulacionForm.value.idRegulacion) });
      } catch (err) {
        this._snackBar.open("Error al guardar cliente", "Error", { duration: 3000 })
      }
    }
  }
  async postUsuarioRegulacion() {
    try {
      let usuario = await this._us.postUsuarioRegulacion(this.usuarioRegulacionForm.value) as any
      this.idUsuarioRegulacion = usuario.idUsuarioRegulacion
      return usuario
    } catch (err) {
      throw err
    }
  }
}
