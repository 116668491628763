import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';

@Injectable({
  providedIn: 'root'
})
export class ServicioService {

  constructor(private _http: HttpClient) { }

  async getServicios(filtros) {
    let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[nombre][like]=%${nombre}%`
    // }
    console.log(filtros);
    return this._http.get(`${URL}/servicio?${filtros}`)
      .toPromise()
  }

  async getServicio(idServicio, query) {
    let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[nombre][like]=%${nombre}%`
    // }
    // console.l
    return this._http.get(`${URL}/servicio/${idServicio}?${query}`)
      .toPromise()
  }

  async postServicio(post) {
    return this._http.post(`${URL}/servicio`, post)
      .toPromise()
  }

  async putServicio(idServicio, put) {
    return this._http.put(`${URL}/servicio/${idServicio}`, put)
      .toPromise()
  }

  async putProductosServicio(idServicio, put) {
    return this._http.put(`${URL}/servicio/productos/${idServicio}`, put)
      .toPromise()
  }

  async putTransporteServicio(idServicio, put) {
    return this._http.put(`${URL}/servicio/${idServicio}/transporte`, put)
      .toPromise()
  }

  async putDictamenServicio(idServicio, put) {
    return this._http.put(`${URL}/servicio/${idServicio}/dictamen`, put)
      .toPromise()
  }

  async patchServicio(idServicio, put) {
    return this._http.patch(`${URL}/servicio/${idServicio}`, put)
      .toPromise()
  }

  async getServicioProductos(idServicio, query) {
    return this._http.get(`${URL}/servicio/${idServicio}/producto?${query}`)
      .toPromise()
  }

  async getServicioProducto(idServicio, idServicioProducto) {
    return this._http.get(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}`)
      .toPromise()
  }

  async postServicioProducto(idServicio, post) {
    return this._http.post(`${URL}/servicio/${idServicio}/producto`, post)
      .toPromise()
  }

  async putServicioProducto(idServicio, idServicioProducto, put) {
    return this._http.put(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}`, put)
      .toPromise()
  }

  async deleteServicioProducto(idServicio, idServicioProducto) {
    return this._http.delete(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}`)
      .toPromise()
  }

  async patchServicioProducto(idServicio, idServicioProducto, status) {
    return this._http.patch(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}`, { action: status })
      .toPromise()
  }

  async getServicioProductoDoc(idServicio, idServicioProducto) {
    return this._http.get(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/documento`)
      .toPromise()
  }

  async postServicioProductoDoc(idServicio, idServicioProducto, post) {
    return this._http.post(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/documento`, post)
      .toPromise()
  }

  async putServicioProductoDoc(idServicio, idServicioProducto, idServicioProductoDoc, put) {
    return this._http.put(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/documento/${idServicioProductoDoc}`, put)
      .toPromise()
  }

  async deleteServicioProductoDoc(idServicio, idServicioProducto, idServicioProductoDoc) {
    return this._http.delete(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/documento/${idServicioProductoDoc}`)
      .toPromise()
  }

  async getServicioProductoReq(idServicio, idServicioProducto) {
    return this._http.get(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/req`)
      .toPromise()
  }

  async postServicioProductoReq(idServicio, idServicioProducto, post) {
    return this._http.post(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/req`, post)
      .toPromise()
  }

  async putServicioProductoReq(idServicio, idServicioProducto, idServicioProductoReq, put) {
    return this._http.put(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/req/${idServicioProductoReq}`, put)
      .toPromise()
  }

  async deleteServicioProductoReq(idServicio, idServicioProducto, idServicioProductoReq) {
    return this._http.delete(`${URL}/servicio/${idServicio}/producto/${idServicioProducto}/req/${idServicioProductoReq}`)
      .toPromise()
  }

  async getServicioDocumento(idServicio) {
    return this._http.get(`${URL}/servicio/${idServicio}/digital`, { responseType: 'blob' })
      .toPromise()
  }

  async postEnviarAsignacion(idServicio) {
    return this._http.get(`${URL}/servicio/${idServicio}/enviar-asignacion`)
      .toPromise()
  }
}