<!-- <p>lista-usuario-regulaciones works!</p> -->
<div><button mat-icon-button matTooltip="Nuevo Usuario" type="button" (click)="agregaRegulacion()" class="gray-icon">
        <mat-icon>add</mat-icon>
    </button>
</div>
<table>
    <tr>
        <td class="p2"><strong></strong></td>
        <td class="p2"><strong>Regulación</strong></td>
        <td class="p2"><strong>Vencimiento</strong></td>
    </tr>
    <tr *ngFor="let usuarioRegulacion of data.id.UsuarioRegulacions; let i= index;">
        <td class="p2">
            <button color="accent" mat-icon-button (click)="handleEditarRegulacion(usuarioRegulacion)">
                <mat-icon>edit</mat-icon>
            </button>
            <button color="warn" mat-icon-button (click)="handleEliminarRegulacion(usuarioRegulacion, i)">
                <mat-icon>delete</mat-icon>
            </button>
        </td>
        <td class="p2">{{usuarioRegulacion.Regulacion.descripcion}}</td>
        <td class="p2">{{usuarioRegulacion.fechaVencimiento | date:'yyyy-MM-dd'}}</td>
    </tr>
</table>