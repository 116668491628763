import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';

@Injectable({
  providedIn: 'root'
})
export class ProductoService {

  constructor(private _http: HttpClient,) { }
  async getProductos(filter) {
    // let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[descripcion][like]=%${nombre}%`
    // }

    return this._http.get(`${URL}/producto?${filter}`)
      .toPromise()
  }

  async getProducto(id) {
    return this._http.get(`${URL}/producto/${id}`)
      .toPromise()
  }

  async postProducto(post) {
    return this._http.post(`${URL}/producto`, post)
      .toPromise()
  }

  async putProducto(id, put) {
    return this._http.put(`${URL}/producto/${id}`, put)
      .toPromise()
  }

  async patchProducto(id, action, patch) {
    return this._http.patch(`${URL}/producto/${id}`, { action, patch })
      .toPromise()
  }
}
