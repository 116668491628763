import { Component, Input, OnInit } from '@angular/core';
import { ProgressSpinnerMode } from '@angular/material/progress-spinner';

@Component({
  selector: 'app-custom-loader',
  templateUrl: './custom-loader.component.html',
  styleUrls: ['./custom-loader.component.sass']
})
export class CustomLoaderComponent implements OnInit {
  @Input() color = "accent";
  @Input() message = "Cargando";
  @Input() value = 0;
  @Input() mode: ProgressSpinnerMode = "indeterminate";
  @Input() show = false;
  @Input() showNoDataMessage = false;
  @Input() noDataMessage = "No se encontraron resultados";
  @Input() type = 0; // 0 - spinner 1 - Progress
  @Input() diameter = 24;
  @Input() stroke = 1;
  constructor() { }

  ngOnInit(): void {
  }

}
