import { Injectable } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  constructor() { }
  fullScreenDialogConfig(data = null) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = { ...dialogConfig, autoFocus: false, disableClose: false, panelClass: "fullscreen-dialog", maxWidth: "none" }
    if (data) dialogConfig.data = data;
    return dialogConfig;
  }
  modalConfig(data = null, dialogConf = {}) {
    let dialogConfig = new MatDialogConfig();
    dialogConfig = { ...dialogConfig, autoFocus: false, disableClose: false, ...dialogConf }
    if (data) dialogConfig.data = data;
    return dialogConfig;
  }
}
