<div class="app-container">
    <mat-tab-group>
        <mat-tab *ngIf="servicioVerificacion" label="Productos">
            <app-productos [productos]="servicioVerificacion ? servicioVerificacion.ServicioProductos: null">
            </app-productos>
        </mat-tab>
        <mat-tab *ngIf="servicioVerificacion" label="Transporte">
            <app-transporte [servicioVerificacion]="servicioVerificacion">

            </app-transporte>
        </mat-tab>
        <mat-tab *ngIf="servicioVerificacion" label="Dictamen">
            <app-dictamen [servicioVerificacion]="servicioVerificacion"></app-dictamen>
        </mat-tab>
    </mat-tab-group>
</div>