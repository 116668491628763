<mat-toolbar class="mat-elevation-z10">
    <button mat-icon-button aria-label="Mostrar Menú" (click)="toggleAppMenu()">
        <mat-icon>menu</mat-icon>
    </button>
    <div class="brand-logo">
        <a [routerLink]="[ '']" class="brand-logo">
            <img [ngStyle]="{ height: 45, width: 165 }" [src]="img" alt="Logo Radar" />
        </a>
    </div>
    <button mat-button (click)="servicios()">Servicios</button>
    <button mat-button (click)="catalogos()">Catalogos</button>
    <span class="spacer"></span>
    <div>
        <button mat-button [matMenuTriggerFor]="menu" fxHide fxShow.gt-xs>

            <mat-icon class="left">person</mat-icon>{{_us.user.nombre}}
        </button>
        <button mat-icon-button [matMenuTriggerFor]="menu" fxShow fxHide.gt-xs>
            <mat-icon>person</mat-icon>
        </button>
        <mat-menu #menu="matMenu">

            <button mat-menu-item (click)="logout()">
                <mat-icon>power_settings_new</mat-icon>
                <span>Salir</span>
            </button>
        </mat-menu>
    </div>
</mat-toolbar>