<form [formGroup]="servicioProductoForm" (ngSubmit)="actualizaProducto()">
    <div class="row">
        <div class="col s12 l4 m6">
            <mat-form-field appearance="fill">
                <mat-label>Regulacion</mat-label>
                <mat-select autoActiveFirstOption formControlName="idRegulacion">
                    <mat-option *ngFor="let regulacion of regulaciones" [value]="regulacion.idRegulacion">
                        {{regulacion.descripcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s12 l9 m9">
            <button class="col s12 l12 m12" color="accent" mat-button (click)="addDocumento()">
                <mat-icon>add</mat-icon>
                Documentos
            </button>
        </div>
        <div style="font-size: small;" *ngFor="let doc of documentos; let i = index;" class="list col s12 l9 m9">
            <span class="col list s6 l6 m6">{{doc.Documento.nombre}}</span>
            <span class="col list s4 l4 m4">{{doc.numero}}</span>
            <span class="spacer"></span>
            <button mat-icon-button (click)="delDocumento(doc, i)" color="accent">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="addDocumento(doc, i)" color="accent">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="text-option col s12 l4 m6">
            <!-- Regulacion: {{servicioProductoForm.value.listoAVerificar}}. -->
            <mat-form-field appearance="fill">
                <mat-label>Listo para verificar?</mat-label>
                <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                <mat-select autoActiveFirstOption formControlName="listoAVerificar"
                    (selectionChange)="listoAVerificar($event)">
                    <mat-option *ngFor="let x of listo" [value]="x.valor">
                        {{x.texto}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
            <!-- {{listo}} -->
        </div>

        <div class="col s12 l4 m6">
            <mat-form-field appearance="fill">
                <mat-label>Verificacion Requerida</mat-label>
                <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                <mat-select autoActiveFirstOption formControlName="tipoVerificacion">
                    <mat-option *ngFor="let verificacion of verificaciones" [value]=verificacion.valor>
                        {{verificacion.texto}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>

        <div class="col s12 l4 m6" *ngIf="servicioProductoForm.value.tipoVerificacion == '0'">
            <mat-form-field appearance="fill">
                <mat-label>Otro</mat-label>
                <input matInput placeholder="" formControlName="otroTipoVerificacion">
            </mat-form-field>
        </div>

        <div class="col s12 l9 m9">
            <button class="col s12 l12 m12" color="accent" mat-button (click)="addRequisito()">
                <mat-icon>add</mat-icon>
                Requisitos Fitosanitarios
            </button>
        </div>
        <div style="font-size: small;" *ngFor="let req of requisitos; let i = index;" class="list col s12 l9 m9">

            <span class="col list s6 l6 m6">{{req.idRequisito == 13? req.otroDesc :
                req.RequisitoFitosanitario.nombre}}</span>
            <span class="col list s4 l4 m4">{{req.cumplimiento==0? "No Cumple": "Si Cumple"}}</span>
            <span class="spacer"></span>
            <button mat-icon-button (click)="delRequisito(req, i)" color="accent">
                <mat-icon>delete</mat-icon>
            </button>
            <button mat-icon-button (click)="addRequisito(req, i)" color="accent">
                <mat-icon>edit</mat-icon>
            </button>
        </div>
        <div class="col s12 l9 m9">
            <button type="submit" class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Actualizar
            </button>
        </div>
    </div>
</form>