<mat-toolbar color="" class="mat-elevation-z8">
    <button mat-icon-button mat-dialog-close>
        <mat-icon>close</mat-icon>
    </button>
    <span>{{title}}</span>
    <span class="spacer"></span>
    <ng-content select=".fullscreen-actions">

    </ng-content>
</mat-toolbar>
<div class="full-screen-container">
    <ng-content>

    </ng-content>
</div>
