import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ListaClientesComponent } from './clientes/lista-clientes/lista-clientes.component';
import { ListaFoliosComponent } from './folios/lista-folios/lista-folios.component';
import { MainComponent } from './main/main.component';
import { ListaProductosComponent } from './productos/lista-productos/lista-productos.component';
import { ListaUbicacionesComponent } from './ubicaciones/lista-ubicaciones/lista-ubicaciones.component';
import { ListaUsuarioComponent } from './usuarios/lista-usuario/lista-usuario.component';
// import { RegistroComponent } from '../servicios/registro/registro.component';
// ListaUsuarioComponent
// import { VerificacionComponent } from './verificacion/verificacion.component';


const routes: Routes = [
    { path: '', component: MainComponent },
    { path: 'lista-clientes', component: ListaClientesComponent },
    { path: 'lista-productos', component: ListaProductosComponent },
    { path: 'lista-ubicaciones', component: ListaUbicacionesComponent },
    { path: 'lista-usuarios', component: ListaUsuarioComponent },
    { path: 'lista-folios', component: ListaFoliosComponent },
    { path: '', redirectTo: '', pathMatch: 'full' },
    { path: '**', redirectTo: '', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class CatalogosRoutingModule { }
