import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { UtilitiesService } from 'src/app/services/utilities/utilities.service';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-dictamen',
  templateUrl: './dictamen.component.html',
  styleUrls: ['./dictamen.component.sass']
})
export class DictamenComponent implements OnInit {
  idServicio: string
  @Input() servicioVerificacion
  cumpleSiNo = [{ valor: 0, texto: "NO" }, { valor: 1, texto: "SI" }]
  cumpleNormas = [{ valor: 0, texto: "NO" }, { valor: 1, texto: "SI" }]
  dictamenForm = new FormGroup({
    noReqCertFitoMovilizacion: new FormControl(null, Validators.required),
    cumpleNormatividad: new FormControl(null, Validators.required),
    descripcionIncumplimiento: new FormControl(''),
    cumplimientoCfmn: new FormControl(null),
    flejeCfmn: new FormControl(null),
    folioCfmn: new FormControl(''),
    cumplimientoCfi: new FormControl(null),
    flejeCfi: new FormControl(null),
    folioCfi: new FormControl(''),
  })
  constructor(private _servicioService: ServicioService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _utilitiesService: UtilitiesService,
    private _confirmDialog: MatDialog,
    private _snakBar: MatSnackBar) {
    this._activatedRoute.params.subscribe(params => {
      this.idServicio = params['idServicio'];
      console.log('Service dICTAMEN #: ', this.idServicio);
    });
  }

  ngOnInit(): void {
    // this.idServicio = params['idServicio'];
    console.log("dICTAMEN INIT");
    let forma = this._utilitiesService.castObjetoAsProperties(Object.keys(this.dictamenForm.controls), this.servicioVerificacion)
    console.log(forma);
    this.dictamenForm.setValue(forma)
    this.modificaValidacionForma()
  }

  modificaValidacionForma() {
    console.log(this.dictamenForm.controls);
    if (this.servicioVerificacion.verificacion == 1 && this.dictamenForm.controls["flejeCfmn"].value == 1) {
      this.dictamenForm.controls["folioCfmn"].setValidators(Validators.required)
      this.dictamenForm.controls["folioCfi"].clearValidators()
    }
    if (this.servicioVerificacion.verificacion == 2 && this.dictamenForm.controls["flejeCfi"].value == 1) {
      this.dictamenForm.controls["folioCfi"].setValidators(Validators.required)
      this.dictamenForm.controls["folioCfmn"].clearValidators()
    }
  }

  imprimeValores() {
    console.log(this.dictamenForm.value)
  }

  actualiza() {
    this.imprimeValores()
    if (this.dictamenForm.valid) {
      try {
        if (!this.dictamenForm.pristine) {
          this.putDictamen(this.dictamenForm.value)
          this.dictamenForm.markAsPristine
          this._snakBar.open("Servicio actualizado!", "Aviso", { duration: 3000 })
        }
        console.log("Entra a cerrar");
        this.showConfirmaFinalizacion("Finalizacion de servicio", "Desea terminar con la verificacion?", "Cancelar", "Aceptar")
      } catch (err) {
        this._snakBar.open("Error al actualizar!" + err[0], "Error", { duration: 3000 })
      }

    }
  }

  async putDictamen(put) {
    let servicio = await this._servicioService.putDictamenServicio(this.idServicio, put) as any
  }


  async showConfirmaFinalizacion(title: string, message: string, cancelButton: string, okButton: string) {
    const dialogRef = this._confirmDialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title, message, cancelButton, okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        if (result) {
          this._servicioService.patchServicio(this.idServicio, { action: 3 })
          this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        console.log(err);
        this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }
}
