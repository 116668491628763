<form [formGroup]="dictamenForm" (ngSubmit)="actualiza()">
    <div class="row">
        <div class="col s12 l8 m12">
            <mat-form-field appearance="fill">
                <mat-label>Requiere Certificado Fitosanitario</mat-label>
                <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                <mat-select autoActiveFirstOption formControlName="noReqCertFitoMovilizacion">
                    <mat-option *ngFor="let req of cumpleSiNo" [value]="req.valor">
                        {{req.texto}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col s12 l8 m12">
            <label>Cumple con la normatividad, requisitos aplicables y/o requisito fitosanitario
                evaluado?</label>
            <div>
                <mat-form-field appearance="fill">
                    <mat-label>Cumple</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="cumpleNormatividad">
                        <mat-option *ngFor="let cumple of cumpleNormas" [value]="cumple.valor">
                            {{cumple.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>

        <!-- *ngIf="dictamenForm.value.cumpleNormatividad=='0'" -->
        <div *ngIf="dictamenForm.value.cumpleNormatividad=='0'" class="col s12 l6 m6">
            <!-- {{dictamenForm.value.cumpleNormatividad}} -->
            <mat-form-field appearance="fill">
                <mat-label>Descripcion</mat-label>
                <input matInput placeholder="" formControlName="descripcionIncumplimiento">
            </mat-form-field>
        </div>


        <div *ngIf="servicioVerificacion.verificacion==1">
            <div class="col s12 l8 m12">
                <label>Debe expedirse CFMN</label>
                <mat-form-field appearance="fill">
                    <mat-label>Debe Expedirse</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="cumplimientoCfmn">
                        <mat-option *ngFor="let cumple of cumpleSiNo" [value]="cumple.valor">
                            {{cumple.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col s12 l8 m12">
                <mat-form-field appearance="fill">
                    <mat-label>Fleje CFMN</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select (selectionChange)="modificaValidacionForma()" autoActiveFirstOption
                        formControlName="flejeCfmn">
                        <mat-option *ngFor="let cumple of cumpleSiNo" [value]="cumple.valor">
                            {{cumple.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

            </div>
            <div class="col s12 l8 m12" *ngIf="dictamenForm.value.flejeCfmn=='1'">
                <mat-form-field appearance="fill">
                    <mat-label>Folio CFMN</mat-label>
                    <input matInput placeholder="" formControlName="folioCfmn">
                </mat-form-field>
                <!-- <mat-checkbox formControlName="flejeCfmn">Fleje CFMN</mat-checkbox> -->
            </div>
        </div>
        <div *ngIf="servicioVerificacion.verificacion==2">
            <div class="col s12 l8 m12">
                <label>Debe expedirse CFI</label>
                <mat-form-field appearance="fill">
                    <mat-label>Debe Expedirse</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="cumplimientoCfi">
                        <mat-option *ngFor="let cumple of cumpleSiNo" [value]="cumple.valor">
                            {{cumple.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col s12 l8 m12">
                <mat-form-field appearance="fill">
                    <mat-label>Fleje CFI</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select (selectionChange)="modificaValidacionForma()" autoActiveFirstOption
                        formControlName="flejeCfi">
                        <mat-option *ngFor="let cumple of cumpleSiNo" [value]="cumple.valor">
                            {{cumple.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>

                <!-- <mat-checkbox formControlName="flejeCfi">Fleje CFI</mat-checkbox> -->
            </div>

            <div *ngIf="dictamenForm.value.flejeCfi=='1'" class="col s12 l6 m6">
                <mat-form-field appearance="fill">
                    <mat-label>Folio CFI</mat-label>
                    <input matInput placeholder="" formControlName="folioCfi">
                </mat-form-field>
            </div>
        </div>
        <div class="col s12 l9 m9">
            <button type="submit" class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Actualizar
            </button>
        </div>
    </div>
</form>