<mat-drawer-container class="digital-container" autosize>
    <div class="digital-sidenav-content">
        
        <app-table title='Listado de Usuarios' [isFetching]="isLoading" [dataSource]="usuarios"
            [columns]="displayedColumns" [count]="resultsLength" matSort [sort]="sort" [pageOptions]="[5,10,25,50,100]"
            [heigth]="'calc(100vh - 300px)'" [pageSize]="pageSize">
            <div class="buttons">
                <button mat-icon-button matTooltip="Actualizar" type="button" (click)="actualizarReporte()"
                    class="gray-icon">
                    <mat-icon>refresh</mat-icon>
                </button>
            </div>
            <div class="buttons">
                <button mat-icon-button matTooltip="Nuevo Usuario" type="button" (click)="showAgregaUsuario(null)"
                    class="gray-icon">
                    <mat-icon>add</mat-icon>
                </button>
            </div>
            <div class="buttons">
                <!-- <button mat-icon-button matTooltip="Filtros" type="button" class="gray-icon" (click)="drawer.toggle()">
                    <mat-icon>filter_list</mat-icon>
                </button> -->
            </div>
            <div class="advancedSearch">
                <div>
                    <button mat-icon-button matTooltip="Busqueda Avanzada" type="button"
                        #popoverTrigger="mtxPopoverTrigger" [mtxPopoverTriggerFor]="popover" mtxPopoverTriggerOn="click"
                        class="gray-icon">
                        <mat-icon>settings</mat-icon>
                    </button>
                    <mtx-popover #popover="mtxPopover" xPosition="before" yPosition="below" [xOffset]="0" [yOffset]="0"
                        [overlapTrigger]="false" [closeOnPanelClick]="false" [closeOnBackdropClick]="true">
                        <app-table-advanced-search>
                            <div class="advanced-search-body">
                                <label id="example-radio-group-label">Buscar por:</label>
                                <mat-radio-group aria-labelledby="example-radio-group-label" class="search-options"
                                    (change)="handleSearchChange($event)">
                                    <mat-radio-button class="search-option-item" *ngFor="let opt of busquedasDispoible"
                                        [value]="opt" [checked]="searchBy.value === opt.value">

                                    </mat-radio-button>
                                </mat-radio-group>

                            </div>
                        </app-table-advanced-search>
                    </mtx-popover>
                </div>
            </div>

            <ng-container *ngFor="let col of displayedColumns; index as i" [matColumnDef]="col">
                <th mat-header-cell *matHeaderCellDef>
                    <span class="col" [matTooltip]="col">{{col}}</span>
                </th>
                <td mat-cell *matCellDef="let element">
                    <span class="col" style="font-size: 12px;"
                        *ngIf="!getObj(col).tipoChip && getObj(col).campo.includes('fecha')"> {{
                        obtenerValor(element[getObj(col).campo]) | date: 'dd/MM/yyyy'}}
                    </span>
                    <span class="col" style="font-size: 12px;"
                        *ngIf="!getObj(col).tipoChip && !getObj(col).campo.includes('fecha')">
                        {{
                        col != "Rol" ? obtenerValor(element[getObj(col).campo]) :
                        obtenerValor(element[getObj(col).campo]) }}
                    </span>
                    <!-- <div *ngIf="getObj(col).tipoChip ">
                        <span class="col chip" [style]="[['background-color', getServicioOStatus(getObj(col).campo,obtenerValor(element[getObj(col).campo]) ).color].join(':'), 
                                        'color: white', 'font-size: 12px'].join(';')">
                            {{obtenerValor(element[getObj(col).campo]) == 9 && getObj(col).campo == "verificacion" ?
                            element.otroServicio :
                            getServicioOStatus(getObj(col).campo,
                            obtenerValor(element[getObj(col).campo]) ).nombre}}
                        </span>
                    </div> -->
                    <div *ngIf="getObj(col).tipoChip ">
                        <span class="col chip" [style]="[['background-color', getStatus(getObj(col).campo,obtenerValor(element[getObj(col).campo]) ).color].join(':'), 
                                        'color: white', 'font-size: 12px'].join(';')">
                            {{getStatus(getObj(col).campo,obtenerValor(element[getObj(col).campo])).nombre}}

                        </span>
                    </div>


                    <!-- <div *ngIf="getObj(col).tipoChip && getObj(col).campo.includes('fecha')">
                        <span class="col chip" [style]="[['background-color', getServicioOStatus(getObj(col).campo,obtenerValor(element[getObj(col).campo]) ).color].join(':'), 
                                                            'color: white', 'font-size: 12px'].join(';')">
                            {{obtenerValor(element[getObj(col).campo]) == 9 && getObj(col).campo == "verificacion" ?
                            element.otroServicio :
                            getServicioOStatus(getObj(col).campo,
                            obtenerValor(element[getObj(col).campo]) ).nombre }}
                        </span>
                    </div> -->

                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu"
                        *ngIf="col == 'Acciones'">
                        <mat-icon>more_vert</mat-icon>
                    </button>
                    <mat-menu class="col" #menu="matMenu">
                        <button mat-menu-item (click)="edita(element['idUsuario'])">
                            <mat-icon>edit</mat-icon>
                            <span>Editar</span>
                        </button>
                        <button mat-menu-item (click)="reset(element)">
                            <mat-icon>send</mat-icon>
                            <span>Resetear</span>
                        </button>
                        <button *ngIf="element.activo === 1" mat-menu-item (click)="desactiva(element)">
                            <mat-icon>cancel</mat-icon>
                            <span>Desactivar</span>
                        </button>
                        <button *ngIf="element.activo === 0" mat-menu-item (click)="activa(element)">
                            <mat-icon>check_circle</mat-icon>
                            <span>Actiar</span>
                        </button>
                        <button mat-menu-item (click)="muestraRegulaciones(element)">
                            <mat-icon>list</mat-icon>
                            <span>Regulaciones</span>
                        </button>
                    </mat-menu>
                </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: 'true'"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </app-table>

    </div>

</mat-drawer-container>