import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from './main/main.component';



const routes: Routes = [
    { path: 'reportes', component: MainComponent },
    { path: '', redirectTo: '/reportes', pathMatch: 'full' },
    { path: '**', redirectTo: '/reportes', pathMatch: 'full' }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ReportesRoutingModule { }
