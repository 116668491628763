import { Injectable } from '@angular/core';
import { UserConfig } from '../../shared/classes/userConfig.class';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  appConfig: any;
  usrConfig: UserConfig;

  constructor() {
    this.loadAppConfig();
  }
  loadAppConfig() {
    try {
      let appCfg = localStorage.getItem("BIT_appConfig");
      if (appCfg) {
        this.appConfig = JSON.parse(appCfg);
      }
    } catch (error) {
      throw (error);
    }
  }
  setPreference(key: string, value: string) {
    if (!this.appConfig) {
      this.appConfig = {};
    }
    this.appConfig[key] = value;
    this.saveAppConfig();
  }
  getPreference(key: string) {
    try {
      return this.appConfig[key];
    } catch (error) {
      return null;
    }
  }
  saveAppConfig() {
    try {
      if (this.appConfig) {
        localStorage.setItem("BIT_appConfig", JSON.stringify(this.appConfig));
      }
    } catch (error) {
      throw (error);
    }
  }
  // User Config
  loadUsrConfig() {
    try {
      let usrConfig = localStorage.getItem("BIT_usrConfig");
      if (usrConfig) {
        this.usrConfig = JSON.parse(usrConfig);
      }
    } catch (error) {
      throw (error);
    }
  }
  setUsrConfig(userCfg: UserConfig) {
    try {
      if (userCfg) {
        localStorage.setItem("BIT_usrConfig", JSON.stringify(userCfg));
        this.loadUsrConfig();
      }
    } catch (error) {
      console.log(error);
      throw (error);
    }
  }
  saveUsrConfig() {
    try {
      if (this.usrConfig) {
        localStorage.setItem("BIT_usrConfig", JSON.stringify(this.usrConfig));
      }
    } catch (error) {
      throw (error);
    }
  }
  clearUsrConfig() {
    localStorage.removeItem("BIT_usrConfig");
    this.usrConfig = null;
  }
  // User Preferences
  setUsrPreference(key: string, value: string) {
    if (!this.usrConfig) {
      this.usrConfig = null;
    }
    this.usrConfig[key] = value;
    this.saveUsrConfig();
  }
  getUsrPreference(key: string) {
    try {
      return this.usrConfig[key];
    } catch (error) {
      return null;
    }
  }
}
