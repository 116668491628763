import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from 'src/app/config/url.services';

@Injectable({
  providedIn: 'root'
})
export class CiudadService {

  constructor(private _http: HttpClient,) { }

  async getCiudad(filter) {
    // let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[nombre][like]=%${nombre}%`
    // }
    // console.l
    return this._http.get(`${URL}/ciudad?${filter}`)
      .toPromise()
  }
}
