import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {
  layoutSubs: Subscription;
  tipoTransSubs: Subscription;
  constructor() { }

  ngOnInit(): void {

  }
}
