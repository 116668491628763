import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';


import { NavbarComponent } from '../catalogos/layout/navbar/navbar.component';
import { SidenavComponent } from '../catalogos/layout/sidenav/sidenav.component';
import { AddUsuarioComponent } from '../catalogos/usuarios/add-usuario/add-usuario.component';
import { ListaUsuarioComponent } from '../catalogos/usuarios/lista-usuario/lista-usuario.component';
import { ListaClientesComponent } from '../catalogos/clientes/lista-clientes/lista-clientes.component';
import { ListaProductosComponent } from '../catalogos/productos/lista-productos/lista-productos.component';
import { ListaUbicacionesComponent } from '../catalogos/ubicaciones/lista-ubicaciones/lista-ubicaciones.component';
import { CatalogosRoutingModule } from './catalogos-routing.module';
import { CatalogosComponent } from './catalogos.component';
import { ListaUsuarioRegulacionesComponent } from './usuarios/lista-usuario-regulaciones/lista-usuario-regulaciones.component';
import { AddUsuarioRegulacionesComponent } from './usuarios/add-usuario-regulaciones/add-usuario-regulaciones.component';
import { ListaFoliosComponent } from './folios/lista-folios/lista-folios.component';
import { AddFoliosComponent } from './folios/add-folios/add-folios.component';



@NgModule({
    declarations: [
        NavbarComponent,
        SidenavComponent,
        AddUsuarioComponent,
        ListaUsuarioComponent,
        ListaClientesComponent,
        ListaProductosComponent,
        ListaUbicacionesComponent,
        CatalogosComponent,
        ListaUsuarioRegulacionesComponent,
        AddUsuarioRegulacionesComponent,
        ListaFoliosComponent,
        AddFoliosComponent
    ],
    imports: [
        SharedModule,
        CommonModule,
        CatalogosRoutingModule
    ],
    // exports: [ProductoComponent]
})
export class CatalogosModule { }
