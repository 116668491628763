import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatSort } from '@angular/material/sort';
import { Router } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { TableComponent } from 'src/app/shared/components/common/tables/table/table.component';
import { merge, of } from 'rxjs';
import { catchError, debounceTime, map, startWith, switchMap } from 'rxjs/operators';
import { CancelarServicioComponent } from 'src/app/modules/servicios/cancelar-servicio/cancelar-servicio.component';
import { UsuarioService } from 'src/app/services/catalogos/usuario.service';
import { AddUsuarioComponent } from '../add-usuario/add-usuario.component';
import { ListaUsuarioRegulacionesComponent } from '../lista-usuario-regulaciones/lista-usuario-regulaciones.component';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DialogService } from '../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-lista-usuario',
  templateUrl: './lista-usuario.component.html',
  styleUrls: ['./lista-usuario.component.sass']
})
export class ListaUsuarioComponent implements OnInit {
  isLoading = true;
  usuarios;

  displayedColumns = ["Acciones", "Nombre", "Rol", "RFC", "Email", "Direccion", "Telefono", "Activo"]; //"patente", "aduana", "pedimento", "eta"
  vals = [
    { nombreColumna: "Acciones", campo: "", tipoChip: false },
    { nombreColumna: "Nombre", campo: "nombre", tipoChip: false },
    { nombreColumna: "Rol", campo: "Rol", tipoChip: false },
    { nombreColumna: "RFC", campo: "rfc", tipoChip: false },
    { nombreColumna: "Email", campo: "email", tipoChip: false },
    { nombreColumna: "Direccion", campo: "direccion", tipoChip: false },
    { nombreColumna: "Telefono", campo: "telefono", tipoChip: false },
    { nombreColumna: "Activo", campo: "activo", tipoChip: true }
  ];
  tipoServicio = [
    { id: 1, nombre: 'Nac', color: 'green' },
    { id: 2, nombre: 'Int', color: '#0000FF' },
    { id: 9, nombre: 'Otro', color: '#FF9900' }
  ];
  tipoStatus = [
    { id: '0', nombre: 'Generado', color: 'blue' },
    { id: '1', nombre: 'Asignado', color: '#FF9900' },
    { id: '2', nombre: 'Verificación', color: '#F597FF' },
    { id: '3', nombre: 'Finalizado', color: '#38761D' },
    { id: '9', nombre: 'Cancelado', color: '#grey' }
  ];
  status = [{ id: '0', nombre: 'Inactivo', color: 'gray' }, { id: '1', nombre: 'Activo', color: 'green' }]
  getStatus(tipo, id) {
    if (tipo == "activo") {
      return this.status.find(st => st.id == id);
    }
  }
  getServicioOStatus(tipo, id) {
    if (tipo == "status") {
      return this.tipoStatus.find(st => st.id == id);
    } else {
      return this.tipoServicio.find(serv => serv.id == id);
    }
  }

  obtenerValor(row) {
    if (typeof row === 'object' && row !== null) {
      return row.descripcion;//[val.campo]
    }
    else {
      return row;
    }
  }
  getObj(value) {
    return this.vals.find(val => val.nombreColumna === value);
  }
  AbrirMenu() {
    this.trigger.openMenu();
  }

  resultsLength = 0;
  pageSize = 10;
  searchBy = { value: "nombre", text: 'Nombre' }
  attributes = 'attributes[0]=nombre&attributes[1]=idRol&attributes[2]=rfc&attributes[3]=email&attributes[4]=direccion&attributes[5]=telefono&attributes[6]=idUsuario&attributes[7]=activo';
  filters = 'filters[activo][eq]=1' // 'filters[status][in]=1,0&filters[fechaSolicitud][between]=2021-08-01,2021-09-12';
  busquedasDispoible = [];

  searchOptions: any[] = [{ value: "fase", text: 'Fase' },
  { value: "movilidad", text: 'Movilidad' },
  { value: "internacional", text: 'Internacional' },
  { value: "otroServicio", text: 'Otro Servicio' },
  ];
  panelOpenState = false;

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  @Input() resultadoReporte;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(TableComponent, { static: false }) public table: TableComponent<any>;
  constructor(private _us: UsuarioService, private _router: Router, private _dialog: MatDialog, private _sb: MatSnackBar, private _ds: DialogService) { }

  ngOnInit(): void {
    // console.log(this.resultadoReporte);
    this.displayedColumns = [...this.displayedColumns];
    // this.servicios = this.resultadoReporte;
    let usrConfig = JSON.parse(localStorage.getItem("BIT_usrConfig"));
    if (usrConfig.idRol != 1) {
      this.filters = `${this.filters}&filters[idRol][ne]=1`
    }
    console.log("Esta es la lista de servicios");
  }

  ngAfterViewInit() {
    this.actualizarReporte()
  }
  handleSearchChange(event) {
    console.log("Busqueda del reporte");
  }
  verMas(element) {
    console.log("Ver mas");
  }
  actualizarReporte() {
    merge(this.table.sort.sortChange, this.table ? this.table.paginator.page : [], this.table.searchValue.valueChanges.pipe(debounceTime(500)))
      .pipe(
        startWith({}),
        switchMap(() => {
          this.isLoading = true;
          // let chipsFilters = this.agregarFiltros();
          return this._us.getUsuarios(`${this.attributes}&${this.filters}${this.table.searchValue.value ? `&filters[${this.searchBy.value}][like]=%25${this.table.searchValue.value.replace("&", "%26")}%25` : ''}&size=${this.table.paginator.pageSize}&page=${this.table.paginator.pageIndex}`);
        }),
        map((data: any) => {
          this.isLoading = false;
          this.resultsLength = data.count;
          return data.rows
          //return [];
        }),
        catchError(() => {
          this.isLoading = false;

          return of([]);
        })
      )
      .subscribe((data: any) => {
        if (data) {
          // this._ss.setPreference("pageSize", this.table.paginator.pageSize.toString());
          this.usuarios = data;
          // this.getFiltros();

        } else {
          this.usuarios = [];
        }
      });
  }

  verifica(id) {
    // console.log(id)
    // console.log(`verificacion/${id}`);
    // this._router.navigate([`servicios/verificacion/${id}`])
  }

  edita(id) {
    // console.log(id)
    // console.log(`editar/${id}`);
    // this._router.navigate([`servicios/registro/${id}`])
    this.showAgregaUsuario(id)
  }

  desactiva(usuario) {
    this.showConfirmaDesactivar({ title: "Desactivar", message: `Confirma desactivar el usuario ${usuario.nombre}`, cancelButton: "Cancelar", okButton: "Aceptar" }, usuario)
  }
  activa(usuario) {
    this.showConfirmaDesactivar({ title: "Activar", message: `Confirma activar el usuario ${usuario.nombre}`, cancelButton: "Cancelar", okButton: "Aceptar" }, usuario)
  }
  reset(usuario) {
    this.showConfirmaDesactivar({ title: "Reset", message: `Confirma resetear el usuario ${usuario.nombre}`, cancelButton: "Cancelar", okButton: "Aceptar" }, usuario)
  }

  imprimir(idServicio) {
  }

  agregaUsuario() {
    // this._router.navigate([`servicios/registro/new`])

  }

  muestraRegulaciones(id) {
    // this._router.navigate([`servicios/registro/new`])
    console.log(this.usuarios);
    const dialogRef = this._dialog.open(ListaUsuarioRegulacionesComponent, {
      // panelClass: "sixtyprc-dialog",
      disableClose: false,
      width: '50%',
      data: {
        id,
        // UsuarioRegulaciones: this.usuarios.find(x => x.idUsuario == id)
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          // this._servicioService.patchServicio(this.idServicio, { action: 3 })
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }

  async showAgregaUsuario(id) {
    const dialogRef = this._dialog.open(AddUsuarioComponent, {
      // panelClass: "sixtyprc-dialog",
      disableClose: false,
      // maxHeight: '60%',
      data: {
        id
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          this.actualizarReporte()
          // this._snakBar.open("Servicio Finalizado!", "Aviso", { duration: 3000 })
        }
      } catch (err) {
        // console.log(err);
        // this._snakBar.open("Error al finalizar el servicio", "Error", { duration: 3000 })
      }
    })
  }

  async showConfirmaDesactivar(parametros, usuario) {
    const dialogRef = this._dialog.open(ConfirmDialogComponent, {
      panelClass: "sixtyprc-dialog",
      disableClose: true,
      maxHeight: '60%',
      data: {
        confirmDialogData: {
          title: parametros.title, message: parametros.message, cancelButton: parametros.cancelButton, okButton: parametros.okButton
        }
      }
    });
    dialogRef.afterClosed().subscribe(async result => {
      try {
        console.log(result);
        if (result) {
          if (parametros.title == "Desactivar") {
            await this._us.patchUsuario(usuario.idUsuario, "deactivate", {})
            this._ds.showSnack("Usuario desactivado correctamente")
          }
          if (parametros.title == "Reset") {
            await this._us.patchUsuario(usuario.idUsuario, "reset", {})
          }
          if (parametros.title == "Activar") {
            await this._us.patchUsuario(usuario.idUsuario, "activate", {})
            this._ds.showSnack("Usuario activado correctamente")
          }
          this.actualizarReporte()
        }
      } catch (err) {
        console.log(err);
        this._sb.open("Error al Desactivar el producto", "Error", { duration: 3000 })
      }
    })
  }

}
