import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogData } from 'src/app/shared/classes/confirmDialogData';
import { ConfirmDialogComponent } from 'src/app/shared/components/common/modals/confirm-dialog/confirm-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private _matSnackBar: MatSnackBar,
    public dialog: MatDialog) { }

  async askFor(title: string, msg: string, keys = true) {
    let okButton = "OK";
    let cancelButton = "Cancel"
    const dialog = this.openConfirmDialog(
      new ConfirmDialogData(title, msg, okButton, cancelButton)
    );
    return new Promise(resolve => {
      dialog.afterClosed().subscribe(result => {
        resolve(result);
      });
    });
  }
  public async showSnack(message: string, action: string = null, type: string = "done") {
    return this._matSnackBar.open(message, action, {
      duration: 3000,
      panelClass: `snack-${type}`
    });
  }
  openConfirmDialog(dialogData: ConfirmDialogData) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = true;
    dialogConfig.data = { confirmDialogData: dialogData };
    return this.dialog.open(ConfirmDialogComponent, dialogConfig);
  }
}
