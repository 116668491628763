<div class="login mat-elevation-z10">
    <div class="login-data-container">
        <div class="progress-container">
            <mat-progress-bar *ngIf="isLoading" mode="indeterminate"></mat-progress-bar>
        </div>
        <div class="data-container">
            <span>Bienvenido</span>
            <h5 class="text-muted">Teclee su usuario y contraseña para iniciar sesión</h5>
            <form [formGroup]="form" (ngSubmit)="login()">
                <div class="row">
                    <div class="col s12 icon-form-field mat-form-field-margin">

                        <mat-form-field appearance="legacy" color="accent">
                            <mat-label>Email</mat-label>
                            <input matInput formControlName="email" />
                        </mat-form-field>
                    </div>

                    <div class="col s12 icon-form-field mat-form-field-margin">
                        <mat-form-field appearance="legacy" color="accent">
                            <mat-label>Password</mat-label>
                            <input matInput formControlName="password" [type]="hide ? 'password' : 'text'" />
                            <button type="button" mat-icon-button matSuffix (click)="hide = !hide"
                                [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <mat-icon>{{ hide ? "visibility_off" : "visibility" }}</mat-icon>
                            </button>
                        </mat-form-field>
                    </div>
                    <div class="col s12 center-align">
                        <button mat-raised-button color="accent" type="submit" [disabled]="!form.valid" class="mt5">

                            <mat-icon class="left">vpn_key</mat-icon>
                            Login
                        </button>
                    </div>
                </div>

            </form>
        </div>
    </div>
    <div class="login-logo">
    </div>
</div>