<form [formGroup]="foliosForm" (ngSubmit)="showConfirmaFolios()">
    <div class="row">
        <div class="title center-align col l12 m12 s12">
            <span>Agrega Productos</span>
        </div>
    </div>
    <div class="row">
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>TipoFolio</mat-label>
                <mat-select autoActiveFirstOption formControlName="tipoFolio">
                    <mat-option *ngFor="let tipo of tipos" [value]="tipo">
                        {{tipo}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Usuario</mat-label>
                <input type="text" placeholder="Escoge TEF" aria-label="Number" matInput formControlName="Usuario"
                    (input)="getUsuario($event)" [matAutocomplete]="usuario">
                <mat-autocomplete autoActiveFirstOption #usuario="matAutocomplete" [displayWith]="displayUsuario">
                    <mat-option *ngFor="let usuario of users" [value]="usuario">
                        {{usuario.nombre}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col s12 l5 m5">
            <mat-form-field appearance="fill">
                <mat-label>Año</mat-label>
                <mat-select autoActiveFirstOption formControlName="anio" (selectionChange)="getConsecutivo($event)">
                    <mat-option *ngFor="let anio of anios" [value]="anio">
                        {{anio}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l3 m3 s5">
            <mat-form-field appearance="fill">
                <mat-label>Inicio</mat-label>
                <input matInput formControlName="ini">
            </mat-form-field>
        </div>
        <div class="col l3 m3 s5">
            <mat-form-field appearance="fill">
                <mat-label>Fin</mat-label>
                <input matInput formControlName="fin">
            </mat-form-field>
        </div>
        <!-- <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Actual</mat-label>
                <input matInput formControlName="actual">
            </mat-form-field>
        </div> -->
        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Agregar
            </button>
        </div>

    </div>
</form>