import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../../shared/shared.module';


import { NavbarComponent } from '../servicios/layout/navbar/navbar.component';
import { SidenavComponent } from '../servicios/layout/sidenav/sidenav.component';
// import { DashboardComponent } from './dashboard.component';
import { MainComponent } from './main/main.component';
import { RegistroComponent } from './registro/registro.component';
import { VerificacionComponent } from './verificacion/verificacion.component';
import { SeviciosRoutingModule } from './servicios-routing.module'
import { ProductosComponent } from './verificacion/productos/productos.component';
import { ProductoComponent } from './registro/producto/producto.component';
import { ServiciosComponent } from './servicios.component';
import { TransporteComponent } from './verificacion/transporte/transporte.component';
import { DictamenComponent } from './verificacion/dictamen/dictamen.component';
import { ProductoVerificacionComponent } from './verificacion/productos/producto-verificacion/producto-verificacion.component';
import { DocumentoComponent } from './verificacion/productos/documento/documento.component';
import { RequisitoComponent } from './verificacion/productos/requisito/requisito.component';
import { ServiciosReporteComponent } from './servicios-reporte/servicios-reporte.component';
import { FinalizarServicioComponent } from './verificacion/finalizar-servicio/finalizar-servicio.component';
import { CancelarServicioComponent } from './cancelar-servicio/cancelar-servicio.component';
import { AsignarServicioComponent } from './asignar-servicio/asignar-servicio.component';



@NgModule({
  declarations: [
    RegistroComponent,
    VerificacionComponent,
    NavbarComponent,
    SidenavComponent,
    MainComponent,
    ProductosComponent,
    ProductoComponent,
    ServiciosComponent,
    TransporteComponent,
    DictamenComponent,
    ProductoVerificacionComponent,
    DocumentoComponent,
    RequisitoComponent,
    ServiciosReporteComponent,
    FinalizarServicioComponent,
    CancelarServicioComponent,
    AsignarServicioComponent,
  ],
  imports: [
    SharedModule,
    CommonModule,
    SeviciosRoutingModule,

    // ServiciosComponent
  ],
  // exports: [ProductoComponent]
})
export class ServiciosModule { }
