import { Component, OnInit, Inject, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ClienteService } from '../../../../services/catalogos/cliente.service';
import { ProductoService } from '../../../../services/catalogos/producto.service';
import { CiudadService } from '../../../../services/catalogos/ciudad.service';
import { UtilitiesService } from '../../../../services/utilities/utilities.service';
import { ServicioService } from '../../../../services/servicios/servicio.service';
import { Router } from '@angular/router';
import { ProductosComponent } from '../../verificacion/productos/productos.component';
import { ThrowStmt } from '@angular/compiler';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AddProductoComponent } from 'src/app/modules/shared/add-producto/add-producto.component';
import { CatalogosService } from 'src/app/services/catalogos/catalogos.service';


@Component({
  selector: 'app-producto',
  templateUrl: './producto.component.html',
  styleUrls: ['./producto.component.sass']
})
export class ProductoComponent implements OnInit {

  // productos = [{ idProducto: 0, descripcion: "Mangos" }, { idProducto: 1, descripcion: "Platanos" }, { idProducto: 2, descripcion: "Aguacates" }]
  total: number
  servicioProductoForm = new FormGroup({
    idProducto: new FormControl(null, Validators.required),
    uso: new FormControl(null, Validators.required),
    cantidad: new FormControl(null, Validators.required),
    presentacion: new FormControl(null, Validators.required),
    cantxPresentacion: new FormControl(null, Validators.required),
    idUnidad: new FormControl(null, Validators.required),
    marcasDescriptivas: new FormControl(),
    puntoEntrada: new FormControl(null),
    datosTransporte: new FormControl(null, Validators.required),
    origen: new FormControl(null, Validators.required),
    procedencia: new FormControl(null, Validators.required),
    idRegulacion: new FormControl(null, Validators.required)
  })
  regulaciones = []
  idServicio: string
  idServicioProducto: string
  origen: Array<any>
  puntoEntrada: Array<any>
  procedencia: Array<any>
  productos: Array<any>
  presentacion: Array<String>

  constructor(
    @Inject(MAT_DIALOG_DATA) private data,
    private dialog: MatDialog,
    private _ciudadService: CiudadService,
    private _productoService: ProductoService,
    private _us: UtilitiesService,
    private _servicioService: ServicioService,
    private _router: Router,
    private _cs: CatalogosService,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<ProductoComponent>) {
    //  this.regulaciones = <any>await this._cs.getRegulaciones()
  }

  async ngOnInit() {
    // console.log(this.data)
    this.idServicio = this.data.idServicio
    this.idServicioProducto = this.data.idServicioProducto
    if (this.idServicioProducto) {
      console.log(this.idServicioProducto);
      this.getServicioProducto()
    }
    this.regulaciones = <any>await this._cs.getRegulaciones()
  }


  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // console.log(charCode)
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && charCode != 46) {
      event.preventDefault();
      return false;
    } else {
      // this.calculaTotal()
      return true;
    }
  }

  calculaTotal() {
    console.log(this.servicioProductoForm.value.cantidad, this.servicioProductoForm.value.cantxPresentacion)
    this.total = this.servicioProductoForm.value.cantidad * this.servicioProductoForm.value.cantxPresentacion
  }

  // async getCiudades(event) {
  //   this.ciudades = <any>await this._ciudadService.getCiudad(`filters[nombre][like]=%${(event.target as HTMLInputElement).value}%`)
  //   console.log(this.ciudades)
  // }

  async getOrigen(event) {
    let value = await this._us.eventToSearch(event)
    this.origen = <any>await this._ciudadService.getCiudad(`filters[nombre][like]=%${value}%`)
    // console.log(this.ciudades)
  }

  async getPuntoEntrada(event) {
    let value = await this._us.eventToSearch(event)
    this.puntoEntrada = <any>await this._ciudadService.getCiudad(`filters[nombre][like]=%${value}%`)
    // console.log(this.ciudades)
  }

  async getProcedencia(event) {
    let value = await this._us.eventToSearch(event)
    this.procedencia = <any>await this._ciudadService.getCiudad(`filters[nombre][like]=%${value}%`)
    // console.log(this.ciudades)
  }

  async getProductos(event) {
    let value = await this._us.eventToSearch(event)
    let result = <any>await this._productoService.getProductos(`filters[descripcion][like]=%${value}%&filters[activo][eq]=1`)
    this.productos = result.rows
  }

  getPresentacion(id) {
    console.log(id)
    this.presentacion = JSON.parse(this.productos.find(x => x.idProducto == id.idProducto).presentacion)
    // servicioProductoForm.value
    // console.log(this.presentacion)
  }


  async guardarProducto() {
    var inserted
    if (!this.servicioProductoForm.pristine) {
      try {
        let producto = {
          idProducto: this.servicioProductoForm.value.idProducto.idProducto,
          uso: this.servicioProductoForm.value.uso,
          cantidad: this.servicioProductoForm.value.cantidad,
          presentacion: this.servicioProductoForm.value.presentacion,
          cantxPresentacion: this.servicioProductoForm.value.cantxPresentacion,
          idUnidad: this.servicioProductoForm.value.idUnidad,
          marcasDescriptivas: this.servicioProductoForm.value.marcasDescriptivas,
          puntoEntrada: this.servicioProductoForm.value.puntoEntrada ? this.servicioProductoForm.value.puntoEntrada.idCiudad : null,
          datosTransporte: this.servicioProductoForm.value.datosTransporte,
          origen: this.servicioProductoForm.value.origen.idCiudad,
          procedencia: this.servicioProductoForm.value.procedencia.idCiudad,
          idRegulacion: this.servicioProductoForm.value.idRegulacion
        }
        console.log(producto);

        if (this.idServicioProducto) {
          inserted = await this.putServicioProducto(producto)
        } else {
          inserted = await this.postServicioProducto(producto)
        }
        this.servicioProductoForm.markAsPristine()
        this._snackBar.open("Producto Guardado", "Aviso", { duration: 3000 })
        // console.log(producto)
      } catch (err) {
        console.log(err);
        this._snackBar.open("Error al guardar producto", "Error", { duration: 3000 })
      }
    }

  }

  displayProducto(value) {
    return value ? value.descripcion : ""
  }

  displayCiudad(value) {
    return value ? value.nombre : ""
  }

  async postServicioProducto(post) {
    let servicio = await this._servicioService.postServicioProducto(this.idServicio, post) as any
    this.idServicioProducto = servicio.idServicioProducto
    return servicio
  }

  async putServicioProducto(put) {
    let servicio = await this._servicioService.putServicioProducto(this.idServicio, this.idServicioProducto, put) as any
    // this._router.navigate([`/${servicio.idServicio}`])
  }

  async getServicioProducto() {
    let producto = <any>await this._servicioService.getServicioProducto(this.idServicio, this.idServicioProducto)
    producto.idProducto = producto.Producto
    producto.origen = producto.Origen
    producto.procedencia = producto.Procedencia
    producto.puntoEntrada = producto.PuntoEntrada
    console.log(producto);
    this.presentacion = JSON.parse(producto.Producto.presentacion)
    this.procedencia = [producto.Procedencia]
    this.origen = [producto.Origen]
    this.puntoEntrada = [producto.PuntoEntrada]
    this.servicioProductoForm.patchValue(producto)

    this.calculaTotal()
  }


  abrirModalParaAgregarProducto() {
    const dialogRef = this.dialog.open(AddProductoComponent, {
      width: '1000px',
      data: {}
    });
    dialogRef.afterClosed().subscribe(async result => {
      console.log(result);
      this.servicioProductoForm.get("idProducto").setValue(result.descripcion);
    });
  }
}
