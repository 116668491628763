<form [formGroup]="servicioProductoForm" (ngSubmit)="guardarProducto()">
    <div class="row">
        <div class="title center-align col l12 m12 s12">
            <span>Productos</span>
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Producto</mat-label>
                <input type="text" placeholder="Escoge" aria-label="Number" matInput formControlName="idProducto"
                    (input)="getProductos($event)" [matAutocomplete]="producto">
                <mat-autocomplete autoActiveFirstOption #producto="matAutocomplete"
                    (optionSelected)="getPresentacion($event.option.value)" [displayWith]="displayProducto">
                    <mat-option *ngFor="let producto of productos" [value]="producto">
                        {{producto.descripcion}}
                    </mat-option>
                </mat-autocomplete>
                <mat-icon matSuffix (click)="abrirModalParaAgregarProducto()" style="cursor:pointer">add</mat-icon>
            </mat-form-field>
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Uso</mat-label>
                <input matInput formControlName="uso">
            </mat-form-field>
        </div>
        <div class="col l2 m2 s6">
            <mat-form-field appearance="fill">
                <mat-label>cantidad</mat-label>
                <input matInput formControlName="cantidad" (keypress)="keyPressNumbers($event)"
                    (change)="calculaTotal()">
            </mat-form-field>
        </div>
        <div class="col l2 m2 s6">
            <mat-form-field appearance="fill">
                <mat-label>Presentacion</mat-label>
                <!-- <input type="text" aria-label="Number" matInput formControlName="presentacion"> -->
                <mat-select formControlName="presentacion">
                    <mat-option *ngFor="let opcion of presentacion" [value]="opcion">
                        {{opcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="col l2 m2 s6">
            <mat-form-field appearance="fill">
                <mat-label>Can x {{servicioProductoForm.value.presentacion}}</mat-label>
                <input matInput formControlName="cantxPresentacion" (keypress)="keyPressNumbers($event)"
                    (change)="calculaTotal()">
            </mat-form-field>
        </div>
        <div class="col l2 m2 s6">
            <mat-form-field appearance="fill">
                <mat-label>Unidad</mat-label>
                <!-- <input type="text" aria-label="Number" matInput formControlName="presentacion"> -->
                <mat-select formControlName="idUnidad">
                    <mat-option value="KG">
                        KG
                    </mat-option>
                    <mat-option value="TON">
                        TON
                    </mat-option>
                    <mat-option value="PZA">
                        PZA
                    </mat-option>
                    <mat-option value="LTS">
                        LTS
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div class="total col l4 m4 s12"
            *ngIf="servicioProductoForm.value.cantidad && servicioProductoForm.value.cantxPresentacion && servicioProductoForm.value.idUnidad">
            {{ total | number:'1.2-2'}}
            {{servicioProductoForm.value.idUnidad}}
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Marcas</mat-label>
                <input matInput formControlName="marcasDescriptivas">
            </mat-form-field>
        </div>

        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Transporte</mat-label>
                <input matInput formControlName="datosTransporte">
            </mat-form-field>
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Punto Entrada</mat-label>
                <input type="text" placeholder="Escoge" aria-label="Number" matInput formControlName="puntoEntrada"
                    [matAutocomplete]="entrada" (input)="getPuntoEntrada($event)">
                <mat-autocomplete autoActiveFirstOption #entrada="matAutocomplete" [displayWith]="displayCiudad">
                    <mat-option *ngFor="let ciudad of puntoEntrada" [value]="ciudad">
                        {{ciudad ? ciudad.nombre: ""}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Origen</mat-label>
                <input type="text" placeholder="Escoge" aria-label="Number" matInput formControlName="origen"
                    [matAutocomplete]="org" (input)="getOrigen($event)">
                <mat-autocomplete autoActiveFirstOption #org="matAutocomplete" [displayWith]="displayCiudad">
                    <mat-option *ngFor="let ciudad of origen" [value]="ciudad">
                        {{ciudad.nombre}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col l6 m6 s12">
            <mat-form-field appearance="fill">
                <mat-label>Procedencia</mat-label>
                <input type="text" placeholder="Escoge" aria-label="Number" matInput formControlName="procedencia"
                    [matAutocomplete]="proc" (input)="getProcedencia($event)">
                <mat-autocomplete autoActiveFirstOption #proc="matAutocomplete" [displayWith]="displayCiudad">
                    <mat-option *ngFor="let ciudad of procedencia" [value]="ciudad">
                        {{ciudad.nombre}}
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
        </div>
        <div class="col s12 l4 m6">
            <mat-form-field appearance="fill">
                <mat-label>Regulacion</mat-label>
                <mat-select autoActiveFirstOption formControlName="idRegulacion">
                    <mat-option *ngFor="let regulacion of regulaciones" [value]="regulacion.idRegulacion">
                        {{regulacion.descripcion}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>


        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Agregar
            </button>
        </div>
    </div>
</form>