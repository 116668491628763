import { Component, OnInit } from '@angular/core';
import { ServicioService } from 'src/app/services/servicios/servicio.service';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.sass']
})
export class MainComponent implements OnInit {

  constructor(private _sv: ServicioService) { }

  resultadoReporte;//contenido de api
  ngOnInit(): void {
    this.obtenerReporte();
  }


  async obtenerReporte() {//Funcion para obtener el reporte de la api/servicio
    // TODO: Obtener dia actual y sumarle uno al dia, para traer solo los registros de hoy y mandarlo al api

    const response = <any>await this._sv.getServicios('filters[status][in]=1,0&filters[fechaSolicitud][between]=2021-08-25,2021-08-26&size=10&page=0');
    this.resultadoReporte = response;
  }

}
