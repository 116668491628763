<mat-sidenav-container class="router-outlet-container">
    <mat-sidenav
        #appSideNav
        [opened]="opened"
        [mode]="mode"
        class="sidenav-container  mat-elevation-z10"
        [fixedInViewport]="fixedInViewport"
        [fixedTopGap]="topGap"
        [fixedBottomGap]="0">

        <mat-nav-list>
            <mat-list-item *ngFor="let menuItm of menu; let i=index" routerLinkActive="active" [routerLink]="menuItm.redirectTo">
                <ng-container *ngIf="menuItm.children">
                    <a [matMenuTriggerFor]="menu">
                        <mat-icon color="primary">{{menuItm.icon}}</mat-icon><span>{{menuItm.name}}</span>
                    </a>
                    <mat-menu #menu="matMenu">
                        <button mat-menu-item *ngFor="let subItem of menuItm.children" [routerLink]="subItem.redirectoTo" mat-menu-item>{{subItem.name}}</button>
                    </mat-menu>
                </ng-container>
                <ng-container *ngIf="!menuItm.children">
                    <a>
                        <mat-icon>{{menuItm.icon}}</mat-icon><span>{{menuItm.name}}</span>
                    </a>
                </ng-container>


            </mat-list-item>
        </mat-nav-list>
    </mat-sidenav>

    <mat-sidenav-content class="sidenav-content">
        <router-outlet></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>
