import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SharedModule } from './shared/shared.module';
import { LoginModule } from './modules/login/login.module';

import { DashboardModule } from './modules/dashboard/dashboard.module';
import { ReportesModule } from './modules/reportes/reportes.module';
import { ServiciosModule } from './modules/servicios/servicios.module';
import { CatalogosModule } from './modules/catalogos/catalogos.module';


import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptorService } from './services/interceptors/token/token-interceptor.service';
import { AddClienteComponent } from './modules/shared/add-cliente/add-cliente.component';
import { AddProductoComponent } from './modules/shared/add-producto/add-producto.component';
import { AddUbicacionComponent } from './modules/shared/add-ubicacion/add-ubicacion.component';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
// import { CatalogosService } from './services/catalogos/catalogos.service';
// import { CatalogosRoutingModule } from './modules/catalogos/catalogos-routing.module';



// import { AddUsuarioComponent } from './catalogos/usuarios/add-usuario/add-usuario.component';

@NgModule({
  declarations: [
    AppComponent,
    AddClienteComponent,
    AddProductoComponent,
    AddUbicacionComponent,
    // AddUsuarioComponent,
    // ListaUsuarioComponent,
    // ListaClientesComponent,
    // ListaProductosComponent,
    // ListaUbicacionesComponent,
    // CatalogosComponent,
    // AddUsuarioComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    LoginModule,
    ReportesModule,
    ServiciosModule,
    DashboardModule,
    CatalogosModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyD7SsGbAqK335or9mo2B9h_0yPy3n7-fBA',
      libraries: ['places']
    })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true,
  }, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
