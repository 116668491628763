import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ClienteService } from 'src/app/services/catalogos/cliente.service';
import { ServicioService } from 'src/app/services/servicios/servicio.service';
import { DocumentoComponent } from '../../servicios/verificacion/productos/documento/documento.component';

@Component({
  selector: 'app-add-cliente',
  templateUrl: './add-cliente.component.html',
  styleUrls: ['./add-cliente.component.sass']
})
export class AddClienteComponent implements OnInit {
  idCliente
  clienteForm = new FormGroup({
    nombre: new FormControl(null, Validators.required),
    direccion: new FormControl(null, Validators.required),
    contactoNombre: new FormControl(null, Validators.required),
    contactoEmail: new FormControl(null, [Validators.required, Validators.email]),
    contactoTelefono: new FormControl(null, [Validators.required]),
  })

  constructor(@Inject(MAT_DIALOG_DATA) private data, private _cs: ClienteService,
    private _snackBar: MatSnackBar,
    public matDialogRef: MatDialogRef<DocumentoComponent>) {
    if (this.data) {
      this.clienteForm.patchValue(this.data.cliente)
      this.idCliente = this.data.cliente.idCliente
    }

  }

  ngOnInit(): void {
  }

  async guardaCliente() {
    if (this.clienteForm.valid && !this.clienteForm.pristine) {
      try {
        if (this.idCliente) {
          await this.putCliente()
        }
        else {
          await this.postCliente()
        }
        this._snackBar.open("Informacion actualizada", "Aviso", { duration: 3000 })
        this.matDialogRef.close({ ...this.clienteForm.value, idCliente: this.idCliente });
      } catch (err) {
        this._snackBar.open("Error al guardar cliente", "Error", { duration: 3000 })
      }
    }
  }

  async postCliente() {
    try {
      let cliente = await this._cs.postCliente(this.clienteForm.value) as any
      this.idCliente = cliente.idCliente

    } catch (err) {
      throw err
    }
  }

  async putCliente() {
    try {
      let cliente = await this._cs.putCliente(this.idCliente, this.clienteForm.value) as any
      this.idCliente = cliente.idCliente
      this.data.cliente = { ...this.data.cliente, ...this.clienteForm.value }

    } catch (err) {
      throw err
    }
  }
}
