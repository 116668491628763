import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from '../storage/storage.service';
import { Router } from '@angular/router';
import { URL } from 'src/app/config/url.services';

@Injectable({
  providedIn: 'root'
})
export class ClienteService {

  constructor(private _http: HttpClient,) { }
  async getClientes(filter) {
    // let filter
    // if (nombre) {
    //   nombre = nombre.replaceAll(" ", "+")
    //   filter = `filters[nombre][like]=%${nombre}%`
    // }
    // console.l
    // console.log(filter)
    // return this._http.get(`${URL}/ciudad?${filter}`)
    return this._http.get(`${URL}/cliente?${filter}`)
      .toPromise()
  }

  async getCliente(id) {
    return this._http.get(`${URL}/cliente/${id}`)
      .toPromise()
  }

  async postCliente(post) {
    return this._http.post(`${URL}/cliente`, post)
      .toPromise()
  }

  async putCliente(id, put) {
    return this._http.put(`${URL}/cliente/${id}`, put)
      .toPromise()
  }

  async patchCliente(id, action, patch) {
    return this._http.patch(`${URL}/cliente/${id}`, { action, patch })
      .toPromise()
  }
}
