import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-fullscreen-dialog',
  templateUrl: './fullscreen-dialog.component.html',
  styleUrls: ['./fullscreen-dialog.component.sass']
})
export class FullscreenDialogComponent implements OnInit {

  @Input() title: string;
  constructor() { }

  ngOnInit() {
  }

}
