<div class="mat-elevation-z8">
    <div class="table-toolbar">
        <div class="search-bar-container animated fadeIn fast" *ngIf="showSearchBar">
            <mat-icon class="search-icon">search</mat-icon>
            <div class="search-bar-wrapper d-flex">
                <mat-form-field appearance="standard" color="accent">
                    <input *ngIf="showSearchBar" matInput [placeholder]="searchLabel" autocomplete="off" #searchTerm [formControl]="searchValue" appAutofocus />
                </mat-form-field>
            </div>
            <button mat-icon-button type="button" (click)="handleSearchClosed()">
                <mat-icon>close</mat-icon>
            </button>
            <ng-content class="advanced-search" select=".advancedSearch">
            </ng-content>
        </div>
        <div class="header-title-wrapper animated fadeIn fast" *ngIf="!showSearchBar"> {{ title }} </div>
        <span class="spacer"></span>
        <div class="header-buttons">
            <button mat-icon-button type="button" (click)="showSearchBar = !showSearchBar">
                <mat-icon>search</mat-icon>
            </button>
            <ng-content class="buttons" select=".buttons"> </ng-content>
        </div>

    </div>
    <div class="filter-row" *ngIf="showFilters">
        <ng-content class="filters" select=".filters">
        </ng-content>
    </div>

    <div class="progress-container">
        <mat-progress-bar *ngIf="isFetching" mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="table-container-overflow" [ngStyle]="heigth && {'height': heigth}">
        <table [ngStyle]="{'min-width.px': minWidth}" mat-table matSort [dataSource]="dataSource" [matSortActive]="sortActive" matSortDirection="asc" class="animated fadeIn">
            <ng-content></ng-content>
        </table>
    </div>

    <mat-paginator [length]="count" [showFirstLastButtons]="showFirstLastButtons" [pageSizeOptions]="pageOptions" [pageSize]="pageSize"></mat-paginator>
</div>
