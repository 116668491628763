<form [formGroup]="clienteForm" (ngSubmit)="guardaCliente()">
    <div class="row">
        <div class="title center-align col l12 m12 s12">
            <span>Agrega Clientes</span>
        </div>
    </div>
    <div class="row">
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Nombre</mat-label>
                <input matInput formControlName="nombre">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Direccion</mat-label>
                <input matInput formControlName="direccion">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Nombre (contacto)</mat-label>
                <input matInput formControlName="contactoNombre">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Email</mat-label>
                <input matInput placeholder="Ej juan@example.com" formControlName="contactoEmail">
            </mat-form-field>
        </div>
        <div class="col l12 m12 s12">
            <mat-form-field appearance="fill">
                <mat-label>Telefono</mat-label>
                <span matPrefix>+52 &nbsp;</span>
                <input matInput placeholder="55 5555 1234" formControlName="contactoTelefono">
            </mat-form-field>
        </div>
        <div class="col s12 l12 m12">
            <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                <mat-icon>save</mat-icon>
                Guardar
            </button>
        </div>


    </div>
</form>