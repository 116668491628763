import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StorageService } from '../../../../services/storage/storage.service';
import { Router } from '@angular/router';
import { UserService } from '../../../../services/user/user.service';
import { DialogService } from '../../../../services/dialog/dialog.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {

  isLoading = false;
  showMsj = false;
  errMsj = "";
  form: FormGroup;
  hide = true;
  constructor(private _ss: StorageService,
    private _ds: DialogService,
    private _router: Router,
    private _us: UserService) {
    if (this._us.isAuthenticated()) {
      this._router.navigate(["/"]);
    }
  }

  ngOnInit(): void {
    let userId = this._ss.getPreference("lastLoginId") || "";
    this.form = new FormGroup({
      email: new FormControl(userId, [Validators.required]),
      password: new FormControl("", [Validators.required])
    });
  }
  async login() {
    if (this.form.valid) {
      try {

        this.isLoading = true;
        const loginData = <any>await this._us.login(this.form.value)
        this._ss.setUsrConfig(loginData);
        this._us.setUserConfig(loginData);
        this._ss.setPreference("lastLoginId", this.form.value.idUsuario);
        this._router.navigate(["/"]);

      } catch (error) {
        const { status } = error;
        if (status === 401) this._ds.showSnack("Wrong user or password", null, "error");
        else this._ds.showSnack("Sing in error. Please try again", null, "error");
      } finally {
        this.isLoading = false;
      }
    }
  }

}
