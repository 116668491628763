import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ServicioService } from 'src/app/services/servicios/servicio.service';

@Component({
  selector: 'app-verificacion',
  templateUrl: './verificacion.component.html',
  styleUrls: ['./verificacion.component.sass']
})
export class VerificacionComponent implements OnInit {

  idServicio
  servicioVerificacion
  constructor(private _activatedRoute: ActivatedRoute, private _servicioService: ServicioService) {
    this._activatedRoute.params.subscribe(params => {
      this.idServicio = params['idServicio'];
      console.log('Service #: ', this.idServicio);
    });

  }

  async ngOnInit() {
    this.servicioVerificacion = await this._servicioService.getServicio(this.idServicio, {}) as any
    console.log(this.servicioVerificacion)
  }

}
