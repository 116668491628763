import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { URL } from '../../config/url.services';

@Injectable({
  providedIn: 'root'
})
export class UtilitiesService {

  constructor(private _http: HttpClient) { }
  uniqueBy(arr, prop) {
    return arr.reduce((a, d) => {
      if (!a.find(itm => itm[prop] === d[prop])) { a.push(d); }
      return a;
    }, []);

  }
  async toExcel(data: any) {
    const response = await this._http.post(`${URL}/utilerias/jsonToExcel`, { data }, { responseType: 'arraybuffer' }).toPromise();
    return response;
  }

  async eventToSearch(event) {
    let value: any
    value = (event.target as HTMLInputElement).value
    value = value.replaceAll(" ", "+")
    return value
  }

  castObjetoAsProperties(arrayProperties: Array<any>, fuente) {
    let obj: any = {}
    // console.log(fuente)
    for (let x = 0; x < arrayProperties.length; x++) {
      obj[arrayProperties[x]] = fuente[arrayProperties[x]]
    }
    // console.log(obj)
    return obj
  }

}
