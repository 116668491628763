import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-onboard',
  templateUrl: './simple-onboard.component.html',
  styleUrls: ['./simple-onboard.component.sass']
})
export class SimpleOnboardComponent implements OnInit {

  @Input() mensaje: string
  constructor() { }

  ngOnInit(): void {
  }

}
