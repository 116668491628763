<!-- <p>registro works!</p> -->
<div class="app-container">
    <form [formGroup]="servicioForm" (ngSubmit)="guardaServicio()">
        <div class="row">
            <div class="col s12 l4 m6">
                <mat-form-field appearance="fill">
                    <mat-label>Lugar</mat-label>
                    <input type="text" placeholder="Escoge" aria-label="Number" matInput id="lugarSolicitud"
                        formControlName="lugarSolicitud" [matAutocomplete]="auto" (input)="getCiudades($event)">
                    <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayCiudad">
                        <mat-option *ngFor="let ciudad of ciudades" [value]="ciudad">
                            {{ciudad.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
            <div class="col s12 l4 m6">
                <mat-form-field appearance="fill">
                    <mat-label>Escoge Fecha</mat-label>
                    <input matInput [matDatepicker]="datepicker" formControlName="fechaSolicitud">
                    <mat-datepicker-toggle matSuffix [for]="datepicker"></mat-datepicker-toggle>
                    <mat-datepicker #datepicker>
                    </mat-datepicker>
                </mat-form-field>
            </div>
            <div class="col s12 l8 m8">
                <!-- <mat-form-field class="col s12 l8 m8" appearance="fill"> -->
                <!-- {{this.verificacion}} - {{servicioForm.value.verificacion}} -->
                <mat-form-field appearance="fill">
                    <mat-label>Dictamen de Verificación</mat-label>
                    <!-- <input placeholder="Escoge" aria-label="Number" matInput formControlName="tipoVerificacion"> -->
                    <mat-select autoActiveFirstOption formControlName="verificacion">
                        <mat-option *ngFor="let verif of verificacion" [value]="verif.valor">
                            {{verif.texto}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <!-- </mat-form-field> -->
            </div>
            <div *ngIf="servicioForm.value.verificacion==9" class="col s12 l12 m12">
                <mat-form-field class="col s12 l8 m8" appearance="fill">
                    <mat-label>Otro Servicio</mat-label>
                    <input matInput formControlName="otroServicio">
                </mat-form-field>
            </div>

            <div class="col s12 l8 m8">
                <mat-form-field appearance="fill">
                    <mat-label>Cliente</mat-label>
                    <input type="text" placeholder="Escoge" aria-label="Number" matInput formControlName="idCliente"
                        [matAutocomplete]="autoCte" (input)="getClientes($event)">
                    <mat-autocomplete autoActiveFirstOption #autoCte="matAutocomplete" [displayWith]="displayCliente">
                        <mat-option *ngFor="let cliente of clientes" [value]="cliente">
                            {{cliente.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="agregaCliente()" style="cursor: pointer;">add</mat-icon>
                </mat-form-field>
            </div>

            <div class="col s12 l8 m8">
                <mat-form-field appearance="fill">
                    <mat-label>Remitente</mat-label>
                    <input type="text" placeholder="Escribe la ubicacion..." aria-label="Number" matInput
                        formControlName="remitente" [matAutocomplete]="autoRem" (input)="getRemitentes($event)">
                    <mat-autocomplete autoActiveFirstOption #autoRem="matAutocomplete" [displayWith]="displayUbicacion">
                        <mat-option *ngFor="let ubicacion of remitentes" [value]="ubicacion">
                            {{ubicacion.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="abrirUbicaciones()" style="cursor: pointer;">add</mat-icon>
                </mat-form-field>
            </div>

            <div class="col s12 l8 m8">
                <mat-form-field appearance="fill">
                    <mat-label>Destinatario</mat-label>
                    <input placeholder="Escribe la ubicacion..." aria-label="Number" matInput #autoComplete
                        formControlName="destinatario" [matAutocomplete]="autoDest" (input)="getDestinatarios($event)">
                    <mat-autocomplete autoActiveFirstOption #autoDest="matAutocomplete"
                        [displayWith]="displayUbicacion">
                        <mat-option *ngFor="let ubicacion of destinatarios" [value]="ubicacion">
                            {{ubicacion.nombre}}
                        </mat-option>
                    </mat-autocomplete>
                    <mat-icon matSuffix (click)="abrirUbicaciones()" style="cursor: pointer;">add</mat-icon>
                </mat-form-field>
            </div>
            <div class="col s12 l8 m8">
                <mat-accordion class="row example-headers-align">
                    <mat-expansion-panel [expanded]="true" hideToggle>
                        <mat-expansion-panel-header>
                            <mat-panel-title>
                                Productos
                            </mat-panel-title>

                            <mat-panel-description>
                                productos agregados
                                <mat-icon>local_grocery_store</mat-icon>
                            </mat-panel-description>
                        </mat-expansion-panel-header>
                        <div>
                            <div class="row smaller" *ngFor="let prod of productos">
                                <mat-icon class="smaller col s2 l2 m2 right-left "
                                    (click)="abrirProducto(prod.idServicioProducto)"> edit
                                </mat-icon>
                                <span class="col s5 l5 m5">{{prod.Producto.descripcion}} </span>
                                <span class="col s3 l3 m3">{{prod.cantidad}} / {{prod.presentacion}}</span>
                                <mat-icon class="col s2 l2 m2 right-align smaller" (click)="deleteProducto(prod)">
                                    delete
                                </mat-icon>
                            </div>
                        </div>

                        <mat-action-row>
                            <button mat-icon-button (click)="abrirProducto()" color="primary" type="button">
                                <mat-icon>add</mat-icon>
                            </button>
                        </mat-action-row>
                    </mat-expansion-panel>
                </mat-accordion>
            </div>

            <div class="col s12 l8 m8">
                <div class="col s12 l4 m4 right">
                    <button type=submit class="col s12 l12 m12" color="accent" mat-raised-button>
                        <mat-icon>save</mat-icon>
                        {{idServicio=="new"?"Registrar": "Actualizar"}}
                    </button>
                </div>
            </div>
        </div>

    </form>

</div>
<div class="float">
    <button (click)="verReporte()" mat-mini-fab>
        <mat-icon style="padding-top: 3px;">arrow_back</mat-icon>
    </button>
</div>