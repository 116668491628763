import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-button-loading',
  templateUrl: './button-loading.component.html',
  styleUrls: ['./button-loading.component.sass']
})
export class ButtonLoadingComponent implements OnInit {
  @Input() a = 1;
  @Input() type = "button";
  @Input() disabled = false;
  @Input() isSubmitting = false;
  @Input() color;
  constructor() { }

  ngOnInit(): void {
  }

}
