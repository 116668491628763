import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';
import { UserService } from '../../user/user.service';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptorService {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  constructor(private _us: UserService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // No Rest Api request
    // console.log(req.url)
    if (!req.url.includes("/api/") || req.url.includes("api/auth")) {
      // Peticion no api o Login 
      return next.handle(req);
    } else {
      const headers = new HttpHeaders({
        Authorization: "Bearer " + this._us.user.token,
      });
      const newReq = req.clone({
        headers,
      });
      return next.handle(newReq).pipe(
        catchError(error => {
          // If the call to token refresh throw error then redirect to login
          if (newReq.url.includes("api/auth/refresh")) {
            this._us.logout(true);
            return throwError(error);
          }
          if (error instanceof HttpErrorResponse && error.status === 401) {
            return this.error401(newReq, next);
          } else {
            return throwError(error);
          }
        }));
    }


  }
  private addToken(request: HttpRequest<any>, token: string) {
    const headers = new HttpHeaders({
      Authorization: "Bearer " + token,
    });
    return request = request.clone({
      headers,
    });
  }
  private error401(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);
      return this._us.refreshToken().pipe(
        switchMap((token: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(token.token);
          return next.handle(this.addToken(request, token.token));
        }),
        catchError(error => {
          // If the call to token refresh throw error then redirect to login
          this._us.logout(true);
          return throwError(error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => {
          return next.handle(this.addToken(request, jwt));
        }));
    }
  }
}
